import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, deleteToken, onMessage } from "firebase/messaging";
import { store } from '../../../store';
import { get, post, remove } from '../../../http';
import { useSnackbar } from 'notistack';
import { Button } from '@mui/material';
import SnackMessage from '../Notification';

const EventEmitter = require('events');

const vapidKey = "BLxAZoef1yQtSgon6PyLKX61h-ixC3x4ZuRgybwOkLyAGO6uTpL_4QIXg81BrJmkFwM2m-ihpPzbLgEDIO-RKEk";
const config = {
  apiKey: "AIzaSyBiJbnbMpKZfAuB3aUYmTkFt2FvhTxWgCI",
  authDomain: "oyde-dev.firebaseapp.com",
  projectId: "oyde-dev",
  storageBucket: "oyde-dev.appspot.com",
  messagingSenderId: "587083697079",
  appId: "1:587083697079:web:4c492aae4a85dcdb01b632"
};

let messaging;


async function setNotificationsToken(token) {
  const prevToken = store.getState().auth.notificationsToken;

  const setNotificationsToken = store.getActions().auth.setNotificationsToken;
  setNotificationsToken(token);

  await store.persist.flush();
  return prevToken;
}

function hasSession() {
  return store.getState().auth.loggedIn;
}

const action = key => (
  <>
    <Button onClick={() => { alert(`I belong to snackbar with key ${key}`); }}>
        Alert
    </Button>
    <Button onClick={() => { }}>
        Dismiss
    </Button>
  </>
);

const notifier = new EventEmitter();

export default function useNotifications() {

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // const startup = async () => {}
  // const bindToken = async () => {}
  // const unbindToken = async () => {}

  const startup = async () => {
    initializeApp(config);
    messaging = getMessaging();

    onMessage(messaging, (payload) => {
      console.log('MESSAGE:', payload);

      if (payload.data?.link) {

        enqueueSnackbar(payload.notification.title, {
          variant: 'info',
          autoHideDuration: 60000,
          action,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          content: (key, message) => (
            <SnackMessage id={key} title={payload.notification.title} message={payload.notification.body} link={payload.data.link} />
          )
        });
      }

      if (payload.data?.code) {
        notifier.emit(payload.data.code, payload.data);
      }
    });

    await bindToken();
  }

  const bindToken = async () => {
    try {
      const token = await getToken(messaging, { vapidKey });
      if (token) {
        const prevToken = await setNotificationsToken(token);
        if (hasSession()) {
          // console.log('NOTIF TOKEN:', token);
          await post("/notifications/token", { token });
          if (prevToken && prevToken !== token) {
            await remove(`/notifications/token?key=${prevToken}`);
          }
        }
      }
    }
    catch(err) {
      console.log(err.message);
      if (err?.code === 'messaging/permission-blocked') {
        // console.log("request permission!");
        // enqueueSnackbar("REQUEST PERMISSIONS");
      }
      
    }
  }

  const unbindToken = async () => {
    try {
      const succeeded = await deleteToken(messaging);
      if (succeeded) {
        const prevToken = await setNotificationsToken(null);
        if (hasSession()) {
          await remove(`/notifications/token?key=${prevToken}`);
        }
      }
    }
    catch(err) {
      console.log(err);
    }
  }

  return { startup, bindToken, unbindToken, notifier };
}