import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { useHistory } from "react-router-dom";
import { Prompt } from "react-router-dom";


export function NavigationConfirm({ condition, message, navigationType, buttonCancel, buttonConfirm, onConfirm: onNavigationConfirm }) {

  const history = useHistory();

  const [openDialog, setOpenDialog] = useState(false);
  const [confirmNavigation, setConfirmNavigation] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);

  useEffect(() => {
    if (confirmNavigation && nextLocation) {

      if (navigationType === 'back') {
        history.goBack();
      }
      else if (navigationType === 'push') {
        history.push(nextLocation.pathname);
      }
      else if (navigationType === 'replace') {
        history.replace(nextLocation.pathname);
      }
    }
  }, [confirmNavigation, nextLocation, history]);

  const handleBlockedNavigation = (nextLocation) => {
    if (!condition) {
      return true;
    } else {
      setOpenDialog(true);
      setNextLocation(nextLocation);
      return false;
    }
  };

  const onConfirm = async () => {
    onNavigationConfirm && (await onNavigationConfirm());
    setOpenDialog(false);
    setConfirmNavigation(true);
  };

  const onCancel = () => {
    setOpenDialog(false);
    setNextLocation(null);
  };

  return (
    <>
      <Prompt when={condition && !confirmNavigation} message={handleBlockedNavigation} />

      <Dialog
        open={openDialog}
        onClose={onCancel}
      >
        {/* <DialogTitle>Confirm Navigation</DialogTitle> */}
        <DialogContent>
          <DialogContentText>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            {buttonCancel || 'Cancelar'}
          </Button>
          <Button onClick={onConfirm} color="primary">
            {buttonConfirm || 'Confirmar'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}