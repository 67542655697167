import React, { useEffect, useState } from 'react';
import { Eventcalendar, localeEs } from '@mobiscroll/react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import bookingService from './services/BookingService';
import { STATUS_ACCEPTED, STATUS_REJECTED } from './BookingState';
import BookingCard from './BookingCard';

export default function BookingsCalendar() {

  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [booking, setBooking] = useState(null);

  useEffect(() =>  {
    async function loadBookings() {

      // const filter = {text , status, confirm, fromDate, toDate, page, pageSize}
      const filter = { page: 1, pageSize: 1000};

      const response = await bookingService.getBookings(filter);
      if (response.succeeded) {
        const data = response.data.items.map(b => {
          return {
            id: b._id,
            title: b.info.serviceName,
            start: new Date(b.info.startDate + 'T' + b.info.startTime),
            end: new Date(b.info.endDate + 'T' + b.info.endTime),
            allDay: false,
            color: b.info.status === STATUS_ACCEPTED ? '#4caf50' : (b.info.status === STATUS_REJECTED ? '#f44336' : '#2196f3'),
            data: b
          }
        });
        setData(data);
      }
    } 
    loadBookings();
  }, []);

  const onEventClick = (ev) => {
    setBooking(ev.event.data);
    setOpen(true);
  }

  const onClose = () => {
    setOpen(false);
  }

  return (
    <>
    <Box display="flex" flexDirection="column" flexGrow={1} sx={{  }}>
      <Eventcalendar theme="material" locale={localeEs} defaultSelectedDate={new Date(2022, 7, 1)}
        data={data}
        onEventClick={onEventClick}
      />
    </Box>

    <Dialog open={open} onClose={onClose} sx={{ }}>
      {/* <DialogTitle>
        Reserva
      </DialogTitle> */}
      <DialogContent sx={{ width: '600px' }}>
        <BookingCard booking={booking} lite={true} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus>Cerrar</Button>
      </DialogActions>
    </Dialog>

    {/* <Modal
      open={open}
      onClose={onClose}
    >
      <BookingCard booking={booking} lite={false} />
    </Modal> */}
    </>
  );
}


// setBookingsFilter({ ...filter, 
//   text: "",
//   status: (status != null) ? status : -1, 
//   fromDate: fromDate || "", 
//   toDate: toDate || "",
//   page: 1,
//   confirm: (confirm != null) ? Boolean(confirm) : null,
//   enabled: true,
// });