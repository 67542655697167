import React from 'react';
import { useLocalStore, useStoreActions, action, thunk } from 'easy-peasy';
import { useHistory } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Alert from '@mui/lab/Alert';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import authService from './services/AuthService';
import useNotifications from './services/NotificationHook';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://oyde.es/">
        OYDE
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {

  const classes = useStyles();
  const history = useHistory();
  const { bindToken } = useNotifications();

  const setUser = useStoreActions((state) => state.auth.setUser);

  const [state, actions] = useLocalStore(() => ({
    username: "",
    password: "",
    error: "",
  
    setError: action((_state, err) => { _state.error = err }),
    setUsername: action((_state, username) => { _state.username = username; }),
    setPassword: action((_state, password) => { _state.password = password; }),
    login: thunk(async (actions, {username, password}) => {
      
      const response = await authService.login(username, password);
      if (response.succeeded) {

        setUser(response.data.access_token);
        actions.setError(); 
        return true;
      }
      actions.setError("Usuario o contraseña incorrectos");
      return false;
    }),
  }));



  const onLogin = async (e) => {
    e.preventDefault();
    const { username, password } = state;

    const succeeded = await actions.login({ username, password });
    if (succeeded) {

      await bindToken();

      // TODO: Redirect to home page...
      history.replace("/providers");
    }
    else {
      // TODO: Show error...

    }
  }

  const onSignup = async () => {
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Inicio de sesión
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Dirección email"
            name="email"
            autoComplete="email"
            autoFocus
            value={state.username}
            onChange={e => actions.setUsername(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Clave"
            type="password"
            id="password"
            autoComplete="current-password"
            value={state.password}
            onChange={e => actions.setPassword(e.target.value)}
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Recordarme"
          /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={e => onLogin(e)}
          >
            Iniciar Sesión
          </Button>

          {state.error && <Alert severity="error">{state.error}</Alert>}

          {/* <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                ¿No recuerdas tu clave?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2" onClick={onSignup}>
                {"Registrarse"}
              </Link>
            </Grid>
          </Grid> */}
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}