import React from 'react';
import { Typography, Stack } from '@mui/material';
import { Done, DoneAll } from '@mui/icons-material';


const statusTitle = {
  PROPOSAL: 'PROPUESTA',
  REVIEWING: 'PROPUESTA EN REVISIÓN',
  PRODUCT: 'PRODUCTO',
  VALIDATED: 'PRODUCTO VALIDADO',
  REJECTED: 'PROPUESTA RECHAZADA',
}

const statusSubtitle = {
  PROPOSAL: '',
  REVIEW: 'No editable',
  PRODUCT: '',
  VALIDATED: 'Confirmar revisión',
  REJECTED: 'Ver mensajes',
}


export default function ProductStatus({ product, showInfo }) {

  if (!product) return null;

  return (
    <Stack direction="row" spacing={1} sx={{ ml: 1 }}>
      {product.status === 'PRODUCT' && <DoneAll fontSize="small" color="success" />}
      {product.status === 'VALIDATED' && <Done fontSize="small" color="success" />}
      <Typography variant="body2" color="GrayText" sx={{ color: product.status !== 'REJECTED' ? '#5B5B5B' : '#D32E30', fontWeight: 'bold' }}>{statusTitle[product.status]}</Typography>
      {showInfo && <Typography variant="caption" color="GrayText" sx={{ color: '#5B5B5B' }}>{statusSubtitle[product.status]}</Typography>}
    </Stack>
  );
};