import React, { useEffect, useState, useCallback } from 'react';
import { Typography, IconButton, Box, Button, Paper, Card, CardHeader, CardContent, CardActions, CardActionArea, Avatar, Badge } from '@mui/material';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Eventcalendar, localeEs } from '@mobiscroll/react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { Home } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { red, green, blue } from '@mui/material/colors';
import { format, startOfISOWeek, endOfISOWeek, add } from 'date-fns/fp';
import { useHistory } from "react-router-dom";
import { useLocalStore, action, thunk } from 'easy-peasy';
import { EventAvailable, QuestionAnswer } from '@mui/icons-material';
import { STATUS_ACCEPTED, STATUS_REJECTED } from './BookingCard';
import classnames from 'classnames';
import useNotifications from '../core/services/NotificationHook';
import bookingService from './services/BookingService';
import bookingImage from './images/reserva.png';
import messagesImage from './images/message.png';
import pendingImage from './images/pending.png';
import { STATUS_REQUESTED } from './BookingState';

const dateFormat = format('yyyy-MM-dd');

const BOOKINGS_NEW_EVENT = "BK:NEW";
const BOOKINGS_UPDATE_EVENT = "BK:UPD";
const BOOKINGS_STATE_EVENT = "BK:ST";
// const MESSAGES_READ_EVENT = "MSG:RD";
const MESSAGE_NEW = "MSG:NEW";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  warning: {
    backgroundColor: red[50],
  },
  warningAvatar: {
    backgroundColor: red[500],
  },
  ok: {
    backgroundColor: green[50],
  },
  okAvatar: {
    backgroundColor: green[500],
  },
  // avatar: {
  //   backgroundColor: red[500],
  // },
  avatarOk: {
    backgroundColor: green[500],
  },
  messageCard: {
    backgroundColor: green[50],
  },
  bookingCard: {
    backgroundColor: blue[50],
  },
  warningCard: {
    backgroundColor: red[50],
  },
  cover: {
    width: 151,
  },
  contentArea: {
    overflow: 'scroll',
    height: 'calc(100vh - 130px)',
  },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

const options = {
  schedule: {
    type: 'day',
    days: false,
    startDay: 1, // Monday
    endDay: 7, // Sunday
    startTime: '13:00',
    endTime: '23:59',
  }
};


const ProvidersHome = () => {

  const classes = useStyles();
  const history = useHistory();
  const { notifier } = useNotifications();

  const [state, actions] = useLocalStore(() => ({

    // STATE
    unreads: 0,
    confirms: 0,
    requests: 0,

    // ACTIONS
    setOverview: action((_state, {unreads, confirms, requests}) => { 
      _state.unreads = unreads;
      _state.confirms = confirms;
      _state.requests = requests;
    }),

    // THUNKS
    getOverviewInfo: thunk(async (_actions, _) => {

      const response = await bookingService.getBookingsOverview();
      if (response.succeeded) {
        _actions.setOverview(response.data);
      }
      else {
        // TODO: muestra error en pantalla...
        console.log(response.message)
      }
    }),

  }), []);

  useEffect(() => { actions.getOverviewInfo() }, []);

  useEffect(() => { 
    notifier.on(BOOKINGS_NEW_EVENT, notificationReceived);
    notifier.on(BOOKINGS_UPDATE_EVENT, notificationReceived);
    notifier.on(BOOKINGS_STATE_EVENT, notificationReceived);
    // notifier.on(MESSAGES_READ_EVENT, notificationReceived);
    notifier.on(MESSAGE_NEW, notificationReceived);

    return function cleanup() { 
      notifier.off(BOOKINGS_NEW_EVENT, notificationReceived);
      notifier.off(BOOKINGS_UPDATE_EVENT, notificationReceived);
      notifier.off(BOOKINGS_STATE_EVENT, notificationReceived);
      // notifier.off(MESSAGES_READ_EVENT, notificationReceived);
      notifier.off(MESSAGE_NEW, notificationReceived);
    };
  }, []);

  const notificationReceived = useCallback(() => actions.getOverviewInfo());
  // const bookingNew = () => { actions.getOverviewInfo(); }
  // const bookingUpdate = () => { actions.getOverviewInfo(); }
  // const bookingState = () => { actions.getOverviewInfo(); }
  // const messagesRead = () => { actions.getOverviewInfo(); }
  // const messageReceived = () => { actions.getOverviewInfo(); }

  const viewNotConfirmed = () => {

    history.push(`/providers/bookings?confirm=true`);
  }

  const viewStatus = (status) => {

    history.push(`/providers/bookings?status=${status}`);
  }

  const viewWeek = (days) => {

    const now = new Date().setDate(new Date().getDate() + days);
    const from = dateFormat(startOfISOWeek(now));
    const to = dateFormat(endOfISOWeek(now));  

    history.push(`/providers/bookings?fromDate=${from}&toDate=${to}`);
  }

  const pendings = state.requests + state.confirms > 0;

  return (
    <Box display='flex' flexDirection='column' justifyContent='start' alignItems='stretch' sx={{ p: 1, flexGrow: 1 }}>

      <Typography variant="button" color="primary" sx={{ fontWeight: 'bold', fontSize: 18, mb: 1 }}>Portal de proveedores</Typography>

      <Grid container spacing={2} columns={16} disableEqualOverflow>

        <Grid xs={16} sm={6} md={4}>
          <TodayDashboardCard title="Servicios" subtitle="Hoy" value={4} />
        </Grid>

        
        
        

        <Grid container xs={16} sm={10} md={12} columns={12} alignItems='start'>

          <Box display='flex' flexDirection='column' justifyContent='start' alignItems='start' columnGap={2} width='100%'>

            <Grid container columns={12} alignItems='start' width='100%' sx={{ p: 2, pr: 0, pb: 0 }} columnSpacing={2}>

              <Grid xs={12} sm={12} md={6}>
                <WeekDashboardCard title="Servicios" subtitle="Semana" value={14} />
              </Grid>

              <Grid xs={12} sm={12} md={6}>
                <PendingBookingsDashboardCard title="Reservas" subtitle="Pendientes revisión" value={2} />
              </Grid>

              {/* <Grid xs={12} sm={12} md={4}>
                <DashboardCard title="Reservas" subtitle="Pendientes revisión" value={0} />
              </Grid> */}

            </Grid>

            <Grid xs={12} width='100%'>
              <MessagesDashboardCard title="Mensajes" subtitle="No tienes mensajes pendientes por visualizar" value={0} />
            </Grid>


            <Grid xs={12} width='100%'>
              <TrendDashboardCard title="Tendencia semanal" subtitle="Servicios" value={0} />
            </Grid>
          </Box>
          
          

          
       

        </Grid>

      </Grid>
    </Box>


    // <Box display='flex' flexDirection='column' justifyContent='start' alignItems='stretch' sx={{ p: 1, flexGrow: 1 }}>

    //   <Typography variant="button" color="primary" sx={{ fontWeight: 'bold', fontSize: 18, mb: 1 }}>Portal de proveedores</Typography>

    //   <Grid container spacing={2} columns={16} disableEqualOverflow>

    //     <Grid xs={16} sm={8} md={4}>
    //       <TodayDashboardCard title="Servicios" subtitle="Hoy" value={4} />
    //     </Grid>

    //     <Grid xs={16} sm={8} md={4}>
    //       <WeekDashboardCard title="Servicios" subtitle="Semana" value={14} />
    //     </Grid>

    //     <Grid xs={16} sm={8} md={4}>
    //       <PendingBookingsDashboardCard title="Reservas" subtitle="Pendientes revisión" value={2} />
    //     </Grid>
            
    //     <Grid xs={16} sm={8} md={4}>
    //       <DashboardCard />
    //     </Grid>

    //   </Grid>
    // </Box>
    
  );
}

export default ProvidersHome;

function DashboardCard({ title, subtitle, value }) {
  return (
    <Paper elevation={2} sx={{ backgroundColor: '#468FEA', height: 120 }}>
      <Box display='flex' flexDirection='column' justifyContent='start' alignItems='stretch' sx={{ p: 2 }}>
        <Box display='flex' flexDirection='row' justifyContent='start' alignItems='center' columnGap={2}>
          <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
            <Avatar sx={{ bgcolor: 'white', color: '#468FEA' }} variant="rounded">
              <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{value}</Typography>
            </Avatar>
          </StyledBadge>

          <Box display='flex' flexDirection='column' justifyContent='start' alignItems='start' sx={{  }}>
            <Typography variant="body1" color="white" sx={{ fontWeight: 'bold' }}>{title}</Typography>  
            <Typography variant="body2" color="#E2E2E2" sx={{ fontWeight: 'normal', textTransform: 'uppercase' }}>{subtitle}</Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}

function TrendDashboardCard({ title, subtitle, value }) {
  return (
    <Paper elevation={2} sx={{ backgroundColor: 'white', border: '1px solid #468FE9' }}>
      <Box display='flex' flexDirection='column' justifyContent='start' alignItems='stretch' sx={{ p: 2 }}>
        <Box display='flex' flexDirection='row' justifyContent='start' alignItems='center' columnGap={2}>
          {/* <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
            <Avatar sx={{ bgcolor: 'white', color: 'gray' }} variant="rounded">
              <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{value}</Typography>
            </Avatar>
          </StyledBadge> */}

          <Box display='flex' flexDirection='column' justifyContent='start' alignItems='start' sx={{  }}>
            <Typography variant="body1" color="gray" sx={{ fontWeight: 'bold' }}>{title}</Typography>  
            <Typography variant="body2" color="gray" sx={{ fontWeight: 'normal', textTransform: 'uppercase' }}>{subtitle}</Typography>
          </Box>
        </Box>

       
          <ResponsiveContainer width="100%" height={200}>
            <LineChart
              // width={500}
              // height={300}
              data={data}
              margin={{
                top: 20,
                right: 20,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" name='Servicios' dataKey="mem" stroke="#568203" strokeWidth={2} activeDot={{ r: 8 }} />
              <Line type="monotone" name='Incidencias' dataKey="disk" stroke="#FE0000" strokeWidth={2} activeDot={{ r: 8 }} />
            </LineChart>
          </ResponsiveContainer>
     

      </Box>
    </Paper>
  );
}

const data = [
  {
    name: '25/3',
    mem: 4,
    // cpu: 2400,
    disk: 0,
    // net: 3000,
  },
  {
    name: '26/3',
    mem: 3,
    // cpu: 1398,
    disk: 0,
    // net: 2500,
  },
  {
    name: '27/3',
    mem: 2,
    // cpu: 4800,
    disk: 0,
    // net: 2800,
  },
  {
    name: '28/3',
    mem: 5,
    // cpu: 3908,
    disk: 1,
    // net: 3100,
  },
  {
    name: '29/3',
    mem: 2,
    // cpu: 4800,
    disk: 0,
    // net: 3080,
  },
  {
    name: '30/3',
    mem: 5,
    // cpu: 3800,
    disk: 0,
    // net: 3120,
  },
  {
    name: '31/3',
    mem: 7,
    // cpu: 4300,
    disk: 2,
    // net: 2950,
  },
];


function PendingBookingsDashboardCard({ title, subtitle, value }) {
  return (
    <Paper elevation={2} sx={{ backgroundColor: '#FF3800', height: 120 }}>
      <Box display='flex' flexDirection='column' justifyContent='start' alignItems='stretch' rowGap={2} sx={{ p: 2 }}>
        <Box display='flex' flexDirection='row' justifyContent='start' alignItems='center' columnGap={2}>
          <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
            <Avatar sx={{ bgcolor: 'white', color: '#FF3800' }} variant="rounded">
              <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{value}</Typography>
            </Avatar>
          </StyledBadge>

          <Box display='flex' flexDirection='column' justifyContent='start' alignItems='start' sx={{  }}>
            <Typography variant="body1" color="white" sx={{ fontWeight: 'bold' }}>{title}</Typography>  
            <Typography variant="body2" color="#E2E2E2" sx={{ fontWeight: 'normal', textTransform: 'uppercase' }}>{subtitle}</Typography>
          </Box>
        </Box>

        <Box display='flex' flexDirection='row' justifyContent='start' alignItems='center' columnGap={2}>
          <Button variant="outlined" sx={{ color: 'white', border: '1px solid white' }} size="small" onClick={_ => {}}>Revisar</Button>
        </Box>
      </Box>
    </Paper>
  );
}

function MessagesDashboardCard({ title, subtitle, value }) {
  return (
    <Paper elevation={2} sx={{ backgroundColor: '#8DB600', height: 120 }}>
      <Box display='flex' flexDirection='column' justifyContent='start' alignItems='stretch' rowGap={2} sx={{ p: 2 }}>
        <Box display='flex' flexDirection='row' justifyContent='start' alignItems='center' columnGap={2}>
          <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
            <Avatar sx={{ bgcolor: 'white', color: '#8DB600' }} variant="rounded">
              <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{value}</Typography>
            </Avatar>
          </StyledBadge>

          <Box display='flex' flexDirection='column' justifyContent='start' alignItems='start' sx={{  }}>
            <Typography variant="body1" color="white" sx={{ fontWeight: 'bold' }}>{title}</Typography>  
            <Typography variant="body2" color="#E2E2E2" sx={{ fontWeight: 'normal', textTransform: 'uppercase' }}>{subtitle}</Typography>
          </Box>
        </Box>

        <Box display='flex' flexDirection='row' justifyContent='start' alignItems='center' columnGap={2}>
          <Button variant="outlined" sx={{ color: 'white', border: '1px solid white' }} size="small" disabled onClick={_ => {}}>Revisar mensajes</Button>
          {/* <Button variant="outlined" sx={{ color: 'white', border: '1px solid white' }} size="small" onClick={_ => {}}>Próxima (8)</Button> */}
        </Box>
      </Box>
    </Paper>
  );
}

function WeekDashboardCard({ title, subtitle, value }) {
  return (
    <Paper elevation={2} sx={{ backgroundColor: '#468FEA', height: 120 }}>
      <Box display='flex' flexDirection='column' justifyContent='start' alignItems='stretch' rowGap={2} sx={{ p: 2 }}>
        <Box display='flex' flexDirection='row' justifyContent='start' alignItems='center' columnGap={2}>
          <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
            <Avatar sx={{ bgcolor: 'white', color: '#468FEA' }} variant="rounded">
              <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{value}</Typography>
            </Avatar>
          </StyledBadge>

          <Box display='flex' flexDirection='column' justifyContent='start' alignItems='start' sx={{  }}>
            <Typography variant="body1" color="white" sx={{ fontWeight: 'bold' }}>{title}</Typography>  
            <Typography variant="body2" color="#E2E2E2" sx={{ fontWeight: 'normal', textTransform: 'uppercase' }}>{subtitle}</Typography>
          </Box>
        </Box>

        <Box display='flex' flexDirection='row' justifyContent='start' alignItems='center' columnGap={2}>
          <Button variant="outlined" sx={{ color: 'white', border: '1px solid white' }} size="small" onClick={_ => {}}>Actual (6)</Button>
          <Button variant="outlined" sx={{ color: 'white', border: '1px solid white' }} size="small" onClick={_ => {}}>Próxima (8)</Button>
        </Box>
      </Box>
    </Paper>
  );
}

function TodayDashboardCard({ title, subtitle, value }) {

  const [data, setData] = useState([]);

  useEffect(() =>  {
    async function loadBookings() {

      // const filter = {text , status, confirm, fromDate, toDate, page, pageSize}
      const filter = { page: 1, pageSize: 1000};

      const response = await bookingService.getBookings(filter);
      if (response.succeeded) {
        // console.log(response.data.items)
        const data = response.data.items.map(b => {
          return {
            id: b._id,
            title: b.info.serviceName,
            start: new Date(b.info.startDate + 'T' + b.info.startTime),
            end: new Date(b.info.endDate + 'T' + b.info.endTime),
            allDay: false,
            color: b.info.status === STATUS_ACCEPTED ? '#4caf50' : (b.info.status === STATUS_REJECTED ? '#f44336' : '#2196f3'),
            data: b
          }
        });
        // console.log(data);
        setData(data);
      }
    } 
    loadBookings();
  }, []);

  return (
    <Paper elevation={2} sx={{ backgroundColor: '#468FEA' }}>
      <Box display='flex' flexDirection='column' justifyContent='start' alignItems='stretch' rowGap={2} sx={{ p: 2 }}>
        <Box display='flex' flexDirection='row' justifyContent='start' alignItems='center' columnGap={2}>
          <StyledBadge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} variant="dot">
            <Avatar sx={{ bgcolor: 'white', color: '#468FEA' }} variant="rounded">
              <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{value}</Typography>
            </Avatar>
          </StyledBadge>

          <Box display='flex' flexDirection='column' justifyContent='start' alignItems='start' sx={{  }}>
            <Typography variant="body1" color="white" sx={{ fontWeight: 'bold' }}>{title}</Typography>  
            <Typography variant="body2" color="#E2E2E2" sx={{ fontWeight: 'normal', textTransform: 'uppercase' }}>{subtitle}</Typography>
          </Box>
        </Box>

        <Box sx={{ borderRadius: '5px', p: 1, backgroundColor: 'white' }}>
          <Eventcalendar theme="material" locale={localeEs} showControls={false} view={options} data={data} defaultSelectedDate={new Date(2022, 7, 3)} />
        </Box>

      </Box>
    </Paper>
  );
}