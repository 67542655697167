import React from 'react';
import { Switch, Route } from "react-router-dom";
import ProductDetails from './ProductDetails';
import ProductList from './ProductList';
import ProductProposal from './ProductProposal';

import Paths from './Paths';


export default function Catalog() {

  return (
    <Switch>

      {/* CATALOG */}
      <Route exact path={Paths.PRODUCTS_LIST}>
        <ProductList />
      </Route>
      <Route exact path={`${Paths.PRODUCTS_PROPOSAL}/:id`}>
        <ProductProposal />
      </Route>
      <Route path={`${Paths.PRODUCTS}/:id`}>
        <ProductDetails />
      </Route>
     
    </Switch>
  );
}