import { GET, UPLOAD, DELETE } from '../../../http';
import { store } from '../../../store';

const FILES_BASE_URL = "https://oyde-files.fra1.cdn.digitaloceanspaces.com";

class FileService {

  uploadFile = (file) => UPLOAD(`/files`, file);
  // downloadFile = (fileId) => DOWNLOAD(`/files/${fileId}`);  
  deleteFile = (fileId) => DELETE(`/files/${fileId}`);
  getMetadata = (fileId) => GET(`/files/${fileId}/metadata`);
  getUrl = (fileId) => `${FILES_BASE_URL}/docs/${fileId}`;

  getPrivateUrl = (fileId) => `http://localhost:3001/api/files/${fileId}`;
  getPublicUrl = (fileId) => `https://oyde-files.fra1.cdn.digitaloceanspaces.com/docs/${fileId}`;

  openFile = async (fileId, filename) => {

    if (!filename) {
      const metadata = await this.getMetadata(fileId);
      filename = metadata?.filename;
    }

    const token = store.getState().auth.token; // Get token from the store
    fetch(this.getPrivateUrl(fileId), {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(err => console.error('Error downloading file:', err));
  }
}

export default new FileService();