import axios from 'axios';
import { store } from './store';

// const JWT_TOKEN = "..."
const UNKNOWN_CODE = -1;

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000,
  responseType: 'json',
  validateStatus: function (status) {
    return status >= 200 && status < 300; // default
  },
});

http.interceptors.request.use((config) => {

  const token = store.getState().auth.token;
  if (token) {
    // console.log(`Authorization: Bearer ${token}`);
    // Do something before request is sent
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

// function succeeded(result) {
//   // return { data: result?.data };
//   return result?.data;
// }

function succeeded(result) {

  const body = result?.data;
  
  const code = body?.code || 0;
  const message = body?.message || null;
  const data = body?.data || body;

  return { code, message, data, succeeded: code === 0 || (code >= 200 && code < 300) };
}

function failed(defaultValue, err) {
  if (err?.response) {
    const body = err.response.data;

    console.log(`Error ${err.response.status}. ${JSON.stringify(body || "")}`);

    return {
      succeeded: false,
      data: defaultValue,
      code: body?.code !== null && body?.code !== undefined ? body.code : err.response.status,
      message: (err.response.status === 401 || err.response.status === 403) ? "Acceso no autorizado" : body?.message || "Error desconocido. Prueba más tarde",
    };
  }
  return { succeeded: false, code: UNKNOWN_CODE, data: defaultValue, message: "Error desconocido. Prueba más tarde" };
}

export async function get(uri, defaultValue = null) {
  try{
    const result = await http.get(uri);
    if (result.status === 200) {
      return succeeded(result);
    }
  }
  catch(err) {
    return failed(defaultValue, err);
  }
  return failed(defaultValue);
}

export async function post(uri, body, defaultValue = null) {
  try{
    const result = await http.post(uri, body);
    if (result.status >= 200 && result.status < 300) {
      return succeeded(result);
    }
  }
  catch(err) {
    return failed(defaultValue, err);
  }
  return failed(defaultValue);
}

export async function put(uri, body, defaultValue = null) {
  try{
    const result = await http.put(uri, body);
    if (result.status >= 200 && result.status < 300) {
      return succeeded(result);
    }
  }
  catch(err) {
    return failed(defaultValue, err);
  }
  return failed(defaultValue);
}

export async function remove(uri, defaultValue = null) {
  try{
    const result = await http.delete(uri);
    if (result.status >= 200 && result.status < 400) {
      return succeeded(result);
    }
  }
  catch(err) {
    return failed(defaultValue, err);
  }
  return failed(defaultValue);
}

export async function UPLOAD(uri, file, defaultValue = null) {
  try{
    const formData = new FormData();
    formData.append('file', file);
    const result = await http.post(uri, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    if (result.status >= 200 && result.status < 300) {
      return result?.data;
    }
  }
  catch(err) {
    console.log(`HTTP UPLOAD Error (${err.toString()})`);
  }
  return defaultValue;
}


export async function GET(uri, defaultValue = undefined) {
  try{
    const result = await http.get(uri);
    if (result.status >= 200 && result.status < 300) {
      return result?.data;
    }
  }
  catch(err) {
    console.log(`HTTP GET Error (${err.toString()})`);
  }
  return defaultValue;
}

export async function DOWNLOAD(uri) {
  try{
    const result = await http.get(uri, { responseType: 'arraybuffer' });
    if (result.status >= 200 && result.status < 300) {
      if (result?.data) {
        const fileData = Buffer.from(result.data, 'binary');
        return fileData;
      }
    }
  }
  catch(err) {
    console.log(`HTTP GET Error (${err.toString()})`);
  }
  return null;
}

export async function POST(uri, body, defaultValue = undefined) {
  try{
    const result = await http.post(uri, body);
    if (result.status >= 200 && result.status < 300) {
      return result?.data;
    }
  }
  catch(err) {
    console.log(`HTTP POST Error (${err.toString()})`);
  }
  return defaultValue;
}

export async function PUT(uri, body, defaultValue = undefined) {
  try{
    const result = await http.put(uri, body);
    if (result.status >= 200 && result.status < 300) {
      return result?.data;
    }
  }
  catch(err) {
    console.log(`HTTP PUT Error (${err.toString()})`);
  }
  return defaultValue;
}

export async function DELETE(uri, defaultValue = undefined) {
  try{
    const result = await http.delete(uri);
    if (result.status >= 200 && result.status < 300) {
      return result?.data;
    }
  }
  catch(err) {
    console.log(`HTTP DELETE Error (${err.toString()})`);
  }
  return defaultValue;
}

