import React from 'react';
import { Typography } from '@mui/material';
import { HourglassEmpty, Done, Clear } from '@mui/icons-material';


export const PAYMENT_STATUS_OUTSTANDING = 0;
export const PAYMENT_STATUS_REJECTED = 1;
export const PAYMENT_STATUS_PAID_OUT = 2;

export const PAYMENT_STATUS_LIST = [
  { id: -1, name: "Todos" },
  { id: PAYMENT_STATUS_OUTSTANDING, name: "Pendiente" },
  { id: PAYMENT_STATUS_REJECTED, name: "Rechazado" },
  { id: PAYMENT_STATUS_PAID_OUT, name: "Pagado" },
];

const getPaymentStateColor = (payment) => {

  const { status } = payment;

  if (status === PAYMENT_STATUS_PAID_OUT) return 'green';
  else if (status === PAYMENT_STATUS_REJECTED) return '#E84C3D';
  else return 'lightblue';
}

export default function PaymentStatus({payment}) {

  if (!payment) return null;

  const { status } = payment;
  const color = getPaymentStateColor(payment);
  let text = '...';
  if (status === PAYMENT_STATUS_OUTSTANDING) text = 'PENDIENTE';
  else if (status === PAYMENT_STATUS_REJECTED) text = 'RECHAZADO';
  else if (status === PAYMENT_STATUS_PAID_OUT) text = 'PAGADO';

  return (
    <div style={{ display: 'flex', paddingLeft: '8px' }}>
      {status === PAYMENT_STATUS_OUTSTANDING && <HourglassEmpty fontSize="small" style={{ color }} />}
      {status === PAYMENT_STATUS_REJECTED && <Clear fontSize="small" style={{ color }} />}
      {status === PAYMENT_STATUS_PAID_OUT && <Done fontSize="small" style={{ color }} />}
      <Typography variant="body2" style={{ color: '#5B5B5B', fontWeight: 'bold', marginLeft: '8px' }}>{text}</Typography>
    </div>
  );
}