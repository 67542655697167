import React, { useEffect } from 'react';
// import classnames from 'classnames';
// import { useStoreState, useStoreActions } from 'easy-peasy';
import { makeStyles } from '@mui/styles';
import { Grid, Typography, TextField, InputAdornment, Chip, Avatar } from '@mui/material';
import { Alert } from '@mui/lab';
import AccessTime from '@mui/icons-material/AccessTime';
import PeopleAlt from '@mui/icons-material/PeopleAlt';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { Place, MeetingRoom, LabelOutlined, Comment as CommentIcon, List as ListIcon, FlightTakeoff, FlightLand, ConfirmationNumber, AccountCircle, ListAlt as DetailsIcon } from '@mui/icons-material';
import { formatWithOptions, isSameDay, isSameYear } from 'date-fns/fp';
import { es } from 'date-fns/locale';
import HTMLRenderer from 'react-html-renderer'
import BookingState from './BookingState';
import Section from '../core/Section';


const LOC_ORIGIN = ["", "UBICACIÓN", "DESPLAZAMIENTO", "ORIGEN"];
const LOC_DEST = ["", "", "", "DESTINO"];
const useStyles = makeStyles(STYLES);

const dateWithYearFormatter = formatWithOptions({ locale: es }, 'HH:mm (d LLL yyyy)');
const dateFlightFormatter = formatWithOptions({ locale: es }, 'HH:mm (d LLL)');

const BookingOverview = ({booking, lite, bookingError, expand, onExpand}) => {

  const classes = useStyles();

  const info = booking?.info;
  const peopleText = info?.pax === 1 ? "persona" : "personas";
  
  let fromDate = "";
  if (info?.startDate && info?.startTime) { 
    fromDate = dateWithYearFormatter(new Date(`${info?.startDate} ${info?.startTime || "00:00"}`));
  }
  let toDate = "";
  if (info?.endDate && info?.endTime) {
    toDate = dateWithYearFormatter(new Date(`${info?.endDate} ${info?.endTime || "00:00"}`));
  }

  return (
    <Section title="DETALLES RESERVA" icon={<DetailsIcon />} loading={!bookingError && !booking} expand={expand} onExpand={onExpand}
      extra={<BookingState booking={booking} />} lite={lite}>

      {bookingError ? 
        <Alert severity="error">{bookingError}</Alert> :

        <Grid container direction="column">
          <Title text="GENERAL" />

          {lite && <Grid container item direction="row">
            <Grid item xs={12}>
              <DetailItem icon={<LabelOutlined />} title="Servicio" content={info?.serviceName} />
            </Grid>
          </Grid>}

          <Grid container item direction="row" alignItems="flex-end">
            <Grid item>
              <DetailItem icon={<AccessTime />} title="Inicio servicio" content={fromDate} />
            </Grid>
            {toDate !== "" && <>
              <Grid item><ArrowForward style={{ marginLeft: '15px', marginRight: '15px' }} /></Grid>
              <Grid item>
                <DetailItem icon={<AccessTime />} title="Finalización servicio" content={toDate} />
              </Grid>
            </>}
          </Grid>
          
          <Grid container item direction="row">
            {info?.client && <Grid item md={8} xs={12}>
              <DetailItem icon={<AccountCircle />} title="Cliente" content={info?.client} />
            </Grid>}

            <Grid item md={info?.client ? 4 : 12} xs={12}>
              <DetailItem icon={<PeopleAlt />} title="Número de personas" content={`${info?.pax} ${peopleText}`} />
            </Grid>
          </Grid>
        
          {info?.advancePayment > 0 && <div style={{ margin: '8px' }}>
            <Chip key={-1} style={{ margin: '3px 10px 3px 0px' }}
              variant="outlined" color="secondary"
              size="small"
              avatar={<Avatar>!</Avatar>}
              label={<span>Pago de cliente de <strong>{`${info.advancePayment || 0} €`}</strong></span>}
            />
          </div>}

          {info?.characteristics.length > 0 && <>
            {/* <Title text="CARACTERÍSTICAS" /> */}
            <span style={{ fontSize: '12px', color: '#757575', marginLeft: '8px', marginTop: '8px' }}>Características</span>
            <div style={{ margin: '8px' }}>
              {/* {info?.advancePayment > 0 && <Chip key={-1} style={{ margin: '3px 10px 3px 0px' }}
                variant="outlined" color="secondary"
                size="small"
                avatar={<Avatar>!</Avatar>}
                label={<span>Pago de cliente de <strong>{`${info.advancePayment || 0} €`}</strong></span>}
              />} */}
              {info?.characteristics.map((char, idx) => (
                <Chip key={idx} style={{ margin: '3px 10px 3px 0px' }}
                  variant="default"
                  size="small"
                  avatar={<Avatar>{idx + 1}</Avatar>}
                  label={char}
                />
              ))}
            </div>
          </>}

          {/* {info?.remarks && <Grid item xs={12}>
            <DetailItem icon={<CommentIcon />} title="Observaciones" content={info?.remarks} />
          </Grid>} */}
          {info?.remarks && <Grid item xs={12}>
            <span style={{ fontSize: '12px', color: '#757575', marginLeft: '8px', marginTop: '8px' }}>Observaciones</span>
            {/* <div style={{ margin: '8px' }}>{info?.remarks}</div> */}
            <div style={{ margin: '8px' }}><HTMLRenderer html={info?.remarks} /></div>
          </Grid>}
          

          <Grid container item direction="row" style={{ marginTop: '8px' }}>
            {info?.address != null && <Grid container item direction="column" md={6} xs={12}>
              <Title text={LOC_ORIGIN[info?.localization || 0]} />
              <AddressItem address={info?.address} />
            </Grid>}
            {info?.destinationAddress != null && <Grid container item direction="column" md={6} xs={12}>
              <Title text={LOC_DEST[info?.localization || 0]} />
              <AddressItem address={info?.destinationAddress} />
            </Grid>}
            {info?.flight != null && <Grid container item direction="column" md={6} xs={12}>
              <Title text="VUELO" />
              <FlightItem flight={info?.flight} />
            </Grid>}
          </Grid>
        </Grid>
      }
    </Section>
  );
}

export default BookingOverview;


function DetailItem({icon, title, content}) {
  return (
    <div style={{ padding: '8px' }}>
      <TextField variant='standard' fullWidth={true} InputLabelProps={{ shrink: true }} multiline InputProps={{ startAdornment: 
        <InputAdornment position="start">
          {icon}
        </InputAdornment> }}
        label={title}
        value={content} 
        
      />
    </div>
  );
}

function AddressItem({address}) {
  if (!address) return null;
  const { location, flat, door, place } = address;

  return (
    <Grid container item direction="column">
      <Grid item>
        <DetailItem icon={<Place />} title="Dirección" content={location} />
      </Grid>
      {(flat != null || door != null) && <Grid item>
        <DetailItem icon={<MeetingRoom />} title="Piso/puerta" content={`${flat} ${door}`} />
      </Grid>}
      {place != null && <Grid item>
        <DetailItem icon={<MeetingRoom />} title="Lugar" content={place} />
      </Grid>}
    </Grid>
  );
}

function FlightItem({flight}) {
  if (!flight) return null;
  const { date, time, flightNumber, terminal, notes, flightType } = flight;

  const flightDate = date && time ? dateFlightFormatter(new Date(`${date} ${time}`)) : "-";

  return (
    <Grid container item direction="column">
      <Grid item>
        <DetailItem icon={flightType == 1 ? <FlightLand /> : <FlightTakeoff />} title={flightType == 1 ? "Llegada" : "Salida"} content={flightDate} />
      </Grid>
      {flightNumber && <Grid item>
        <DetailItem icon={<ConfirmationNumber />} title="Número vuelo" content={flightNumber} />
      </Grid>}
      {terminal && <Grid item>
        <DetailItem icon={<Place />} title="Terminal" content={terminal} />
      </Grid>}
      {notes && <Grid item>
        <DetailItem icon={<CommentIcon />} title="Observaciones" content={notes} />
      </Grid>}
    </Grid>
  );
}

function Title({text}) {
  return (
    <div style={{ margin: '4px 8px', backgroundColor: '#6C6C6C', borderRadius: '3px' }}>
      <Typography style={{ margin: '2px 8px', fontWeight: 'bold', color: 'white' }}>{text}</Typography>
    </div>
  );
}

// function localizationText(localization) {
//   // LOCALIZATION_PROVIDER = 1;
//   // LOCALIZATION_DISPLACEMENT = 2;
//   // LOCALIZATION_JOURNEY = 3;

//   return LOCALIZATIONS[localization];
// }

function STYLES(theme) {
  return {
    characteristics: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
  };
}