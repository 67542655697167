import { useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Event, ViewAgendaOutlined, FormatListBulleted } from '@mui/icons-material';
import Divider from '@mui/material/Divider';
import { useLocation, useHistory } from "react-router-dom";
import { Box, Paper, Typography, ToggleButton } from '@mui/material';
import ToggleButtonGroup, {
  toggleButtonGroupClasses,
} from '@mui/material/ToggleButtonGroup';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: theme.shape.borderRadius,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
    {
      marginLeft: -1,
      borderLeft: '1px solid transparent',
    },
}));

export default function SectionMenu({ items }) {

  const location = useLocation();
  const history = useHistory();

  const selectedPath = useMemo(() => {
    // Return the items path that current location starts with. The most concrete path is returned in case of multiple matches.
    return (items || []).map(i => i.path).reduce((acc, path) => {
      return location.pathname.startsWith(path) ? path : acc;
    });
  }, [location.pathname, items]);


  const onSelected = (event, path) => {
    history.push(path);
  };

  return (
    <div>
      <Paper
        elevation={0}
        sx={{
          backgroundColor: 'transparent',
          display: 'flex',
          // border: (theme) => `1px solid ${theme.palette.divider}`,
          flexWrap: 'wrap',
        }}
      >
        <StyledToggleButtonGroup
          size="small"
          value={selectedPath}
          exclusive
          onChange={onSelected}
        >

          {(items || []).map(i => <SectionItem key={i.path} {...i} />)}
            
         
        </StyledToggleButtonGroup>
        {/* <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
        <StyledToggleButtonGroup
          size="small"
          value={formats}
          onChange={handleFormat}
          aria-label="text formatting"
        >
          <ToggleButton value="bold" aria-label="bold">
            <FormatBoldIcon />
          </ToggleButton>
          <ToggleButton value="italic" aria-label="italic">
            <FormatItalicIcon />
          </ToggleButton>
          <ToggleButton value="underlined" aria-label="underlined">
            <FormatUnderlinedIcon />
          </ToggleButton>
          <ToggleButton value="color" aria-label="color" disabled>
            <FormatColorFillIcon />
            <ArrowDropDownIcon />
          </ToggleButton>
        </StyledToggleButtonGroup> */}
      </Paper>
    </div>
  );
}

export function SectionItem({ path, title, icon }) {

  return (
    <ToggleButton value={path} sx={{
      textTransform: 'none',
      '&.Mui-selected': { // Target the selected state
        backgroundColor: '#BAD9EB', // Custom background color for selected state
        // '&:hover': {
        //   backgroundColor: 'dodgerBlue', // Custom background color for hover state
        // },
      }
    }}>
      {icon}
      <Typography variant="subtitle2" sx={{ ml: 1 }}>
        {title}
      </Typography>
      {/* <Box sx={{ ml: 1 }}>{title}</Box> */}
    </ToggleButton>
  );
}