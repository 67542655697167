import SectionMenu, { SectionItem } from '../core/SectionMenu';
import { Event, ViewAgendaOutlined, FormatListBulleted, Diversity3Outlined as TeamIcon, StickyNote2Outlined as InfoIcon, ChatBubbleOutline as ChatIcon } from '@mui/icons-material';
import Paths from './Paths';

export function BookingsMenu() {
  return (
    <SectionMenu items={[
      { path: Paths.BOOKINGS_LIST, title: "Lista", icon: <FormatListBulleted /> },
      { path: Paths.BOOKINGS_CALENDAR, title: "Calendario", icon: <Event /> },
      { path: Paths.BOOKINGS_SCHEDULE, title: "Agenda", icon: <ViewAgendaOutlined /> },
    ]} />
  );
}

export function BookingDetailsMenu() {
  return (
    <SectionMenu items={[
      { path: Paths.BOOKINGS, title: "Detalle", icon: <InfoIcon /> },
      { path: Paths.BOOKINGS_CALENDAR, title: "Equipo", icon: <TeamIcon /> },
      { path: Paths.BOOKINGS_SCHEDULE, title: "Mensajes", icon: <ChatIcon /> },
    ]} />
  );
}
