import React, { useState, useCallback, useEffect } from 'react';
import classnames from 'classnames';
import { makeStyles } from '@mui/styles';
import { Collapse, Paper, Typography, Card, CardActions, IconButton, LinearProgress, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const useStyles = makeStyles(STYLES);

function Section({children, title, extra, icon, loading, autoExpand, defaultExpand, expand, onExpand = (exp) => {}, lite}) {

  // Assign font size and color to the icon
  icon = icon ? React.cloneElement(icon, { fontSize: 'small', color: 'primary' }) : null;

  const classes = useStyles();
  const [expanded, setExpanded] = useState(autoExpand ? defaultExpand : expand);

  useEffect(() => onExpand(expanded), [expanded]);
  useEffect(() => {
    if (!autoExpand) {
      setExpanded(expand);
    }
  }, [expand]);

  const onExpanded = useCallback(() => {
    setExpanded((oldExpanded) => !oldExpanded);
  }, []);
  
  return (
    <Card className={classes.card} elevation={2} sx={{ border: '1px solid #D7D7D7' }}>
      <CardActions classes={{ root: classes.actionRoot }} sx={{ backgroundColor: '#F5F5F5' }}>
        <Box display="flex" flexDirection="row" justifyContent="start" alignItems="center" sx={{ ml: 1 }}>
          {icon !== undefined && <div style={{ marginRight: '10px' }}>{icon}</div>}
          <Typography variant="subtitle2" gutterBottom className={classes.typography} color="primary">{title}</Typography>
        </Box>
        <div className={classes.icons} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          {extra && <div style={{ marginRight: '16px' }}>{extra}</div>}
          <IconButton
            aria-label="Show more"
            className={classnames(classes.expand, { [classes.expandOpen]: expanded })}
            onClick={onExpanded}
          >
            <ExpandMoreIcon />
          </IconButton>
          {/* <IconButton className={classes.expand}>
            <CloseIcon />
          </IconButton> */}
        </div>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Paper className={classes.collapse} sx={lite ? { padding: 2 } : { }}>
          
            {loading && <div><LinearProgress /></div>}
            {!loading && children}
          
        </Paper>
      </Collapse>
    </Card>
  );
}

export default Section;

function STYLES(theme) {
  return {
    root: {
      // [theme.breakpoints.up('sm')]: {
      //     minWidth: '344px !important',
      // },
    },
    card: {
      backgroundColor: '#EBEBEB', // '#DCEAF5',
      width: '100%',
    },
    typography: {
      fontWeight: 'bold',
    },
    actionRoot: {
      padding: '8px 8px 8px 16px',
      justifyContent: 'space-between',
    },
    icons: {
      marginLeft: 'auto',
    },
    expand: {
      padding: '8px 8px',
      transform: 'rotate(0deg)',
      transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    collapse: {
      padding: 16,
    },
    checkIcon: {
      fontSize: 20,
      color: '#b3b3b3',
      paddingRight: 4,
    },
    button: {
      padding: 0,
      textTransform: 'none',
    },
    label: {
      border: '0px',
      marginRight: '0px',
    },
  };
}