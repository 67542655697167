import { Grid, Typography } from '@mui/material';
import { SentimentDissatisfiedOutlined } from '@mui/icons-material';

export default function Empty ({ title, subtitle}) {
  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
      <SentimentDissatisfiedOutlined sx={{ fontSize: '100px', color: '#A1A1A1', mb: 2 }} />
      <Typography variant="h6" sx={{ color: '#5B5B5B' }}>{title || 'No se ha encontrado nada'}</Typography>
      <Typography variant="body1" sx={{ color: '#5B5B5B' }}>{subtitle || ''}</Typography>
    </Grid>
  );
}