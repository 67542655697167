import { useState, useRef, useEffect } from 'react';
import { Alert, Box, Stack, Button, IconButton, Typography, Avatar, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { AttachFileOutlined as DetailsIcon, CloudUploadOutlined as CloudIcon, Cancel as DeleteIcon, Image as ImageIcon, Article as TextIcon  } from '@mui/icons-material';
import Section from '../core/Section';
import fileService from '../core/services/FileService';


export default function ProductAttachments({ readonly, files, onFilesChange, updatedAt }) {

  const uploads = useRef([]);

  useEffect(() => {
    if (updatedAt) {
      console.log('ProductAttachments updated at:', updatedAt);
      uploads.current = [];
    }
  }, [updatedAt]);
  

  const [uploadingFile, setUploadingFile] = useState();
  const [expanded, setExpanded] = useState(true);
  const onExpand = (exp) => setExpanded(exp);


  const onUploading = (filename) => {
    setUploadingFile(filename);
  }

  const onUploaded = (fileId, metadata) => {
    setUploadingFile(undefined);
    uploads.current.push(fileId);

    const { filename, access } = metadata;
    const file = { id: fileId, filename, access };
    
    // Update files
    onFilesChange([...files, file]);
  }

  const onDelete = async (fileId) => {
    if (uploads.current.includes(fileId)) {
      // Remove file from server
      const result = await fileService.deleteFile(fileId);
      if (result.ok) {
        // Update files
        onFilesChange(files.filter(f => f.id !== fileId));
        uploads.current = uploads.current.filter(f => f !== fileId);
      }
    }
    else {
      // Update files
      onFilesChange(files.filter(f => f.id !== fileId));
    }
  }

  return (
    <Section title="DOCUMENTOS" icon={<DetailsIcon />} loading={false} expand={expanded} onExpand={onExpand} lite={false}
      extra={
        <>
          {!readonly && <FileUpload onUploading={onUploading} onUploaded={onUploaded} />}
        </>
      }>

      <span style={{ fontSize: '12px', color: '#1876D2', marginLeft: '16px' }}>Documentos adjuntos que aportan información adicional</span>

      {(files || []).length > 0 ? (
        <Box display="flex" flexDirection="column" sx={{ p: 2 }}>
          <FileList readonly={readonly} files={files} onDelete={onDelete} />
          {uploadingFile !== undefined && (
            <FileItem index={files.length + 1} fileId={undefined} filename={uploadingFile} />
          )}
        </Box>
      ) : (
        <Alert severity="info" sx={{ mt: 1 }}>No hay documentos adjuntos</Alert>
      )}
      
    </Section>
  );
}

function FileItem({ readonly, index, fileId, filename, onDownload, onDelete }) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    onDelete(fileId);
    handleClose();
  };

  return (
    <Box sx={{ m: 0, p: 0 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#F5F5F5', borderRadius: 1, p: 1, mb: 1 }}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar sx={{ bgcolor: '#1876D2', width: 24, height: 24, fontSize: '12px' }}>{index + 1}</Avatar>
          <Typography variant="body2" sx={{ color: '#5B5B5B', pl: 1 }}>{filename}</Typography>
        </Stack>
        {fileId !== undefined && <Stack direction="row" alignItems="center" spacing={2}>
          <Button variant="text" size='small' onClick={() => onDownload(fileId, filename)}>Descargar</Button>
          {!readonly && (
            <>
              <IconButton size="small" color="error" onClick={handleClickOpen}><DeleteIcon fontSize='8px' /></IconButton>
              <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Confirmar eliminación</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    ¿Estás seguro de que deseas eliminar este archivo?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancelar</Button>
                  <Button onClick={handleDelete} color="error">Eliminar</Button>
                </DialogActions>
              </Dialog>
            </>
          )}
        </Stack>}
      </Box>
    </Box>
  );
}

function FileList({ readonly, files, onDelete }) {

  const onDownload = async (fileId, filename) => {
    await fileService.openFile(fileId, filename);
  }

  return (
    <>
    {(files || []).map((file, idx) => (
      <FileItem key={idx} readonly={readonly} index={idx} fileId={file.id} filename={file.filename} onDownload={onDownload} onDelete={onDelete} />
    ))}
    </>
  );
}

function FileUpload({ onUploading, onUploaded }) {

  const [uploading, setUploading] = useState(false);

  const onChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        console.log(file);

        setUploading(true);
        onUploading && onUploading(file.originalname);

        const result = await fileService.uploadFile(file);
        if (result.ok) {

          const uploadResponse = result.data;
          if (uploadResponse) {

            const { fileId, metadata } = uploadResponse;
           
            console.log('File uploaded ID:', fileId);
            onUploaded && onUploaded(fileId, metadata);
          }
        }
      }
      finally {
        setUploading(false);
      }
    }
  };

  return (
    <Stack direction="row" gap={1}>
      <Button variant="contained" component="label" size='small' startIcon={<CloudIcon />} disabled={uploading}>
        Subir archivo
        <input type="file" hidden onChange={e => onChange(e)} />
      </Button>
    </Stack>
  );
}