import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { AppBar, Toolbar, Typography, IconButton, Button, Badge, Hidden, Box, Divider } from '@mui/material';
import { Menu, MenuOpen, ChatBubble, Notifications, MenuRounded } from '@mui/icons-material';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useLocation } from "react-router-dom";
// import logoImage from './images/oyde.png';
import logoImage from './images/oyde-logo.png';
import SessionButton from './SessionButton';
import { BookingsMenu, BookingDetailsMenu } from '../providers/BookingMenus';
import Paths from '../providers/Paths';

const SectionTitles = {
  [Paths.BOOKINGS]: "Reservas",
  [Paths.AVAILABILITIES]: "Disponibilidad",
  [Paths.PAYMENTS]: "Pagos",
  [Paths.REGISTRIES]: "Registro de proveedor",
  [Paths.PRODUCTS]: "Productos",
  [Paths.MESSAGES]: "Mensajes",
  [Paths.CONTRACTS]: "Contratos",
  [Paths.ISSUES]: "Incidencias",
  [Paths.TEAM]: "Equipo",
  [Paths.SETTINGS]: "Configuración",
  // Should be the last one
  [Paths.HOME]: "Inicio",
}

const useStyles = makeStyles(theme => ({
  menuButton: {
    // marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    height: '42px',
    margin: '5px 10px 0 0',
    // marginRight: '10px',
    // marginBottom: '10px'
  },
  buttonLabel: {
    textTransform: 'none',
  }
})); // #FBBC05


const Navbar = () => {
  const classes = useStyles();

  const location = useLocation();
  
  const { collapsed } = useStoreState((state) => state.menu);
  const { toggleMenu } = useStoreActions((actions) => actions.menu);
  const { messages, notifications } = useStoreState((state) => state.providers);
  const { loggedIn, session } = useStoreState((state) => state.auth );
  // const [open, setOpen] = useState(false);

  const isPath = (paths) => {
    // Return true if the current location matches any of the target paths.
    if (Array.isArray(paths)) {
      return paths.some(target => location.pathname.startsWith(target));
    }
    return location.pathname.startsWith(paths);
  }

  const isIdPath = (path) => {
    // Return true if the current location matches {path / [number]}
    return location.pathname.match(new RegExp(`${path}/[0-9]+`));
  }

  const getSectionTitle = (path) => {
    // Return title if the location.pathname starts with the path.
    const key = Object.keys(SectionTitles).find(key => path.startsWith(key));
    if (key) {
      return SectionTitles[key];
    }
  }

  // console.log('collapsed:', collapsed)
  // const handleOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // <AppBar position="static" color="transparent" style={{ color: '#222222', backgroundColor: '#01226B' }}>
  // <AppBar position="static" color="transparent" style={{ color: '#222222', backgroundColor: '#76BEDC' }}></AppBar>

  return (
    <AppBar position="sticky" color="default" elevation={0}>
      <Toolbar>
        {loggedIn && <Hidden mdUp>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            className={classes.menuButton}
            onClick={toggleMenu}
          >
            {collapsed && <Menu />}
            {!collapsed && <MenuOpen />}
          </IconButton>
        </Hidden>}

        <IconButton size="small" color='primary' sx={{ mr: 2 }} onClick={_ => toggleMenu()}>
          <MenuRounded />
        </IconButton>

        {/* <img className={classes.logo} src={logoImage} alt="Logo" /> */}

        <Hidden xsDown>
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle1" className={classes.title} color="primary" sx={{ fontWeight: 'normal',  mr: 2 }}>
              {session?.name || ""}
            </Typography>
            {session?.name !== undefined && <Divider orientation="vertical" flexItem />}
            <Typography variant="subtitle1" className={classes.title} color="primary" sx={{ fontWeight: 'bold', ml: 2 }}>
              {getSectionTitle(location.pathname)}
            </Typography>
          </Box>
        </Hidden>

        <div style={{ flexGrow: 1 }} />
        
        {/* <Button color="inherit" onClick={handleOpen}>
          Signup
        </Button> */}

        {/* <IconButton size="small" style={{ marginRight: '30px', color: 'white' }}>
          <Badge badgeContent={notifications.length} variant="standard" color="error">
            <Notifications />
          </Badge>
        </IconButton> */}

        {/* <IconButton size="small" style={{ marginRight: '30px', color: 'white' }}>
          <Badge badgeContent={messages.length} variant="standard" color="error">
            <ChatBubble />
          </Badge>
        </IconButton> */}

        {isPath([Paths.BOOKINGS_LIST, Paths.BOOKINGS_CALENDAR, Paths.BOOKINGS_SCHEDULE]) && <BookingsMenu />}
        {isIdPath(Paths.BOOKINGS) && <BookingDetailsMenu />}

        <SessionButton />

      </Toolbar>
      {/* <ModalDialog open={open} handleClose={handleClose} /> */}
      
    </AppBar>
  );
}

export default Navbar;