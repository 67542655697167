import React, { useState, useMemo } from 'react';
import { Alert, Typography, IconButton, Box, Chip, Avatar, Stack, Button, Select, MenuItem } from '@mui/material';
import { ListAlt as DetailsIcon, AccessTime, People, Euro, Close as CancelIcon, Cancel as DeleteIcon, AddCircle as AddIcon } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useStoreActions, useStoreState } from 'easy-peasy';
import Editor from 'react-simple-wysiwyg';
import Section from '../core/Section';
import ProductStatus from './ProductStatus';
import { Title, FormItem } from '../core/Forms';


export default function ProposalEditor() {

  const { product } = useStoreState((state) => state.products);
  const { updateProduct } = useStoreActions((state) => state.products);

  // const [age, setAge] = React.useState('');

  const onTypeChange = (type) => {
    updateProduct({ ...product, proposal: { ...product.proposal, type } });
  };

  const [expanded, setExpanded] = useState(true);
  const onExpand = (exp) => setExpanded(exp);

  const lite = true;
  const readonly = product?.status !== 'PROPOSAL';
  const proposal = product?.proposal;

  const onChange = (value, field) => {
    if (!readonly){
      updateProduct({ ...product, proposal: { ...proposal, [field]: value } });
    }
  }

  return (
    <Section title="DETALLES" icon={<DetailsIcon />} loading={false} expand={expanded} onExpand={onExpand}
      extra={<ProductStatus product={product} />} lite={lite}>

      <Grid container direction="column" sx={{ /*overflowX: 'hidden', overflowY: 'scroll', height: `calc(100vh - 160px)`, width: '100%', p: 0*/ }}>
     
        <Grid xs={12}>
          <FormItem editable title="Nombre del producto" value={proposal?.name} onChange={v => onChange(v, 'name')} disabled={readonly} />
        </Grid>
          
        <Grid xs={12}>
          <Explain text="Especifica la duración del servicio en minutos" />
          <FormItem editable icon={<AccessTime />} title="Duración" value={proposal?.duration} onChange={v => onChange(v, 'duration')} disabled={readonly} />
        </Grid>

        <Grid xs={12}>
          <Explain text="Selecciona el tipo de producto" />
        </Grid>

        <Grid xs={12}>
          <Select sx={{ ml: 1, mt: 1, minWidth: '300px', fontSize: '14px' }} disabled
            size='small'
            value={proposal?.type || "0"}
            label="Tipo de producto"
            onChange={e => onTypeChange(e.target.value)}
          >
            <MenuItem value={""}>
              <em>None</em>
            </MenuItem>
            <MenuItem value={"0"}>Otros</MenuItem>
            <MenuItem value={"1"}>Twenty</MenuItem>
            <MenuItem value={"2"}>Thirty</MenuItem>
          </Select>
        </Grid>

        <Grid xs={12}>
          <Title text="DESCRIPCIÓN" style={{ marginTop: '24px' }} />
          <Explain text="Describe el producto con todos los detalles que son importantes" />
          <Box sx={{ p: 2, pb: 1, fontSize: '14px' }}>
            <Editor containerProps={{ style: { resize: 'vertical', minHeight: '350px' } }} value={proposal?.description} onChange={e => onChange(e.target.value, 'description')} />
          </Box>
        </Grid>

        <Grid xs={12}>
          <OptionList title="OPCIONES" description="Listado de todos los extras opcionales (con precio y número de personas incluidas)" values={proposal?.options} onChange={v => onChange(v, 'options')} editable={!readonly} />
        </Grid>

        <TagList title="QUÉ INCLUYE" description="Listado de todos los extras que incluye el producto" values={proposal?.included} onChange={v => onChange(v, 'included')} editable={!readonly} />
        <TagList title="A TENER EN CUENTA" description="Cosas importantes que debe saber el cliente antes de realizar la actividad" values={proposal?.considerations} onChange={v => onChange(v, 'considerations')} editable={!readonly} />

      </Grid>
      
    </Section>
  );
}



function OptionView({ option, onDelete, idx }) {

  const { minPax, maxPax } = option;
  let paxText = '';

  if (minPax === 0 && maxPax === 0) {
    paxText = 'Sin límite de personas';
  }
  else if (minPax === maxPax) {
    paxText = `Para ${minPax} personas`;
  }
  else if (minPax === 0 && maxPax > 0) {
    paxText = `Hasta ${maxPax} personas`;
  }
  else if (minPax > 0 && maxPax > 0) {
    paxText = `De ${minPax} a ${maxPax} personas`;
  }
  else if (minPax > 0 && maxPax === 0) {
    paxText = `A partir de ${minPax} personas`;
  }
  else {
    paxText = undefined;
  }

  return (
    <Box sx={{ backgroundColor: '#F5F5F5', borderRadius: 1, p: 1, mb: 2 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center">
          <Avatar sx={{ bgcolor: '#1876D2', width: 24, height: 24, fontSize: '12px' }}>{idx + 1}</Avatar>
          {/* <Typography variant="caption" color="GrayText" sx={{ color: '#5B5B5B', fontWeight: 'bold', pl: 1 }}>{option.name}</Typography> */}
          <Typography variant="body2" sx={{ color: '#5B5B5B', pl: 1 }}>{option.name}</Typography>
        </Stack>
        {onDelete && <IconButton size="small" color="error" onClick={() => onDelete(idx)}><DeleteIcon fontSize='8px' /></IconButton>}
      </Stack>
      <Stack direction="row" spacing={1} mt={1}>
        <Chip style={{ margin: '3px 10px 3px 0px', backgroundColor: 'white' }}
          variant="default"
          size="small"
          avatar={<Avatar><Euro fontSize='14px' /></Avatar>}
          label={`${option.price}€ / ${option.priceType === 'GROUP' ? 'grupo' : 'persona'}`}
        />
        {paxText !== undefined && <Chip style={{ margin: '3px 10px 3px 0px', backgroundColor: 'white' }}
          variant="default"
          size="small"
          avatar={<Avatar><People fontSize='14px' /></Avatar>}
          label={paxText}
        />}
      </Stack>
    </Box>
  );
}


function Explain({ text }) {
  return (
    <span style={{ fontSize: '12px', color: '#1876D2', marginLeft: '16px' }}>{text}</span>
  );
}

export function TagList({ title, description, values, onChange, editable }) {

  const [tag, setTag] = useState('');

  const onAdd = () => {
    if (tag) {
      onChange([...values, tag]);
      setTag('');
    }
  }

  const onDelete = (idx) => {
    onChange(values.filter((_, i) => i !== idx));
  }

  return (
    <Grid xs={12}>
      <>
        {title !== undefined && <Title text={title} style={{ marginTop: '24px' }} />}
        {description !== undefined && <span style={{ fontSize: '12px', color: '#1876D2', marginLeft: '16px' }}>{description}</span>}
        {!values?.length && <Alert style={{ marginTop: '16px' }} severity="info" sx={{ ml: 2, mr: 2 }}>No hay elementos</Alert>}
        <Box sx={{ m: 1, p: 1 }}>
          {(values || []).map((text, idx) => (
            <Box key={idx} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#F5F5F5', borderRadius: 1, p: 1, mb: 1 }}>
              <Stack direction="row" alignItems="center">
                <Avatar sx={{ bgcolor: '#1876D2', width: 24, height: 24, fontSize: '12px' }}>{idx + 1}</Avatar>
                <Typography variant="body2" sx={{ color: '#5B5B5B', pl: 1 }}>{text}</Typography>
              </Stack>
              {editable && <IconButton size="small" color="error" onClick={() => onDelete(idx)}><DeleteIcon fontSize='8px' /></IconButton>}
            </Box>
          ))}
        </Box>
      </>
      
      {editable && <Box sx={{ pl: 1, pr: 1 }}>
        <FormItem title="Nuevo elemento  " value={tag} onChange={setTag} editable controls={
          <Stack direction="row" spacing={0}>
            <IconButton size="small" disabled={!tag} onClick={() => setTag('')}><CancelIcon fontSize='8px' /></IconButton>
            <Button variant='text' color="primary" size="small" startIcon={<AddIcon fontSize='8px'/>} disabled={!tag} onClick={onAdd}>Añadir</Button>
          </Stack>
          } 
        />
      </Box>}

    </Grid>
  );
} 


function OptionList({ title, description, values, onChange, editable }) {

  const [option, setOption] = useState({ name: '', description: '', price: 0, priceType: 'PERSON', minPax: 0, maxPax: 0 });

  const onAdd = () => {
    if (option.name) {
      onChange([...values, option]);
      setOption({ name: '', description: '', price: 0, priceType: 'PERSON', minPax: 0, maxPax: 0 });
    }
  }

  const onDelete = (idx) => {
    onChange(values.filter((_, i) => i !== idx));
  }

  return (
    <Grid xs={12}>
      <>
        {title !== undefined && <Title text={title} style={{ marginTop: '24px' }} />}
        {description !== undefined && <span style={{ fontSize: '12px', color: '#1876D2', marginLeft: '16px' }}>{description}</span>}
        {!values?.length && <Alert style={{ marginTop: '16px' }} severity="info" sx={{ ml: 2, mr: 2 }}>No hay elementos</Alert>}
        <Box sx={{ m: 1, p: 1 }}>
          {(values || []).map((option, idx) => (
            <OptionView key={idx} option={option} onDelete={editable ? onDelete : null} idx={idx} />
          ))}
        </Box>
      </>
      
      {editable && <Box sx={{ pl: 1, pr: 1 }}>
        <Stack direction="row" spacing={0} alignItems="center">
          <FormItem title="Precio" value={option.price} onChange={(price) => setOption({ ...option, price })} editable type="number" InputProps={{ startAdornment: <Euro fontSize="small" /> }} />
          <Select
            size='small' sx={{ fontSize: '14px', minWidth: '180px' }}
            value={option.priceType}
            onChange={(e) => setOption({ ...option, priceType: e.target.value })}
            displayEmpty
          >
            <MenuItem value="PERSON" style={{ fontSize: '14px' }}>Por persona</MenuItem>
            <MenuItem value="GROUP" style={{ fontSize: '14px' }}>Por grupo</MenuItem>
          </Select>
          {option.priceType === 'GROUP' && (
            <>
              <FormItem title="Min Pax" value={option.minPax} onChange={(minPax) => setOption({ ...option, minPax })} editable type="number" style={{ width: '80px' }} />
              <FormItem title="Max Pax" value={option.maxPax} onChange={(maxPax) => setOption({ ...option, maxPax })} editable type="number" style={{ width: '80px' }} />
            </>
          )}
        </Stack>
        
          <FormItem title="Nombre" value={option.name} onChange={(name) => setOption({ ...option, name })} editable controls={
            <Stack direction="row" spacing={0}>
              <IconButton size="small" disabled={!option.name} onClick={() => setOption({ name: '', description: '', price: 0, priceType: 'PERSON', minPax: 0, maxPax: 0 })}><CancelIcon fontSize='8px' /></IconButton>
              <Button variant='text' color="primary" size="small" startIcon={<AddIcon fontSize='8px'/>} disabled={!option.name} onClick={onAdd}>Añadir</Button>
            </Stack>
          } />
       
      </Box>}

    </Grid>
  );
}
