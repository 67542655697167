import { action, thunk } from 'easy-peasy';
import { formatWithOptions } from 'date-fns/fp';
import { es } from 'date-fns/locale';
import bookingService from './services/BookingService';

const dateFormatter = formatWithOptions({ locale: es }, 'yyyy-MM-dd');

export const providersStore = {

  messages: [1, 2, 3],
  notifications: [],

  bookings: [],
  totalBookings: 0,

  payments: [],
  paymentsFilter: {
    enabled: false,
    status: -1,
    fromDate: dateFormatter(new Date()),
    toDate: "",
  },

  currentBooking: null,
  currentPayment: null,

  bookingError: null,
  paymentError: null,

  paymentLoading: false,

  bookingsFilter: {
    enabled: false,
    text: "",
    status: -1,
    // fromDate: dateFormatter(new Date()),
    fromDate: dateFormatter(new Date(new Date().setFullYear(new Date().getFullYear() - 3))),
    toDate: "",
    confirm: null,
    page: 1,
  },

  setBookings: action((state, bookings) => {
    state.bookings = bookings;
  }),
  setPayments: action((state, payments) => {
    state.payments = payments;
  }),
  setCurrentBooking: action((state, booking) => {
    state.currentBooking = booking;
  }),
  setBookingError: action((state, err) => {
    state.bookingError = err;
  }),
  setCurrentBookingPayment: action((state, payment) => {
    state.currentPayment = payment;
  }),
  setPaymentError: action((state, err) => {
    state.paymentError = err;
  }),
  setPaymentLoading: action((state, loading) => {
    state.paymentLoading = loading;
  }),
  // setCurrentBookingMessages: action((state, messages) => {
  //   state.currentMessages = messages;
  // }),
  setCurrentBookingStatus: action((state, {status}) => {
    if (state.currentBooking) {
      state.currentBooking.info.status = status;
    }
  }),
  removeBookingConfirmation: action((state, payload) => {
    if (state.currentBooking) {
      state.currentBooking.confirm = false;
    }
  }),
  setBookingsFilter: action((state, filter) => {
    state.bookingsFilter = filter;
  }),
  setTotalBookings: action((state, totalBookings) => {
    state.totalBookings = totalBookings;
  }),
  resetBookingsFilter: action((state, _) => {
    state.bookingsFilter = {
      enabled: true,
      text: "",
      status: -1,
      fromDate: dateFormatter(new Date()),
      toDate: "",
      confirm: null,
      page: 1,
    }
  }),
  emptyBookingList: action((state, _) => {
    state.totalBookings = 0;
    state.bookings = [];
    state.bookingsFilter.enabled = false;
  }),
  incrementBookingNotifications: action((state, bookingId) => {
    state.bookings = state.bookings.map(b => {
      if (b.bookingId === bookingId) {
        return { ...b, unreadMessages: b.unreadMessages + 1 };
      }
      return b;
    });
  }),
  setPaymentsFilter: action((state, filter) => {
    state.paymentsFilter = filter;
  }),
  resetPaymentsFilter: action((state, _) => {
    state.paymentsFilter = {
      enabled: true,
      status: -1,
      fromDate: dateFormatter(new Date()),
      toDate: "",
    }
  }),


  fetchBookings: thunk(async (actions, {filter, pageSize}) => {

    const response = await bookingService.getBookings({ ...filter, pageSize });
    if (response.succeeded) {

      actions.setBookings(response.data.items);
      actions.setTotalBookings(response.data.total);
    }
    else {
      actions.setBookings([]);
      actions.setTotalBookings(0);
      // TODO: muestra error en pantalla...
      console.log(response.message)
    }
  }),

  fetchBookingDetails: thunk(async (actions, payload) => {

    actions.setCurrentBooking(null);

    const response = await bookingService.getBookingDetails(payload);
    if (response.succeeded) {
      actions.setCurrentBooking(response.data);
      actions.setBookingError();
    }
    else {
      actions.setCurrentBooking(null);
      actions.setBookingError(response.message);
    }
  }),

  fetchBookingPayment: thunk(async (actions, payload) => {

    actions.setPaymentLoading(true);
    actions.setCurrentBookingPayment(null);

    const response = await bookingService.getBookingPayment(payload);
    if (response.succeeded) {
      actions.setCurrentBookingPayment(response.data);
      actions.setPaymentError();
    }
    else {
      actions.setCurrentBookingPayment(null);
      actions.setPaymentError(response.message);
    }
    actions.setPaymentLoading(false);
  }),

  fetchPayments: thunk(async (actions, {filter}) => {

    const response = await bookingService.getPayments(filter);
    if (response.succeeded) {

      actions.setPayments(response.data);
    }
    else {
      actions.setPayments([]);
      // TODO: muestra error en pantalla...
      console.log(response.message)
    }
  }),

  // fetchBookingMessages: thunk(async (actions, payload) => {

  //   const response = await bookingService.getBookingMessages(payload);
  //   if (response.succeeded) {
  //     actions.setCurrentBookingMessages(response.data);
  //   }
  //   else {
  //     actions.setCurrentBookingMessages([]);
  //     // TODO: muestra error en pantalla...
  //     console.log(response.message)
  //   }
  // }),

  updateBookingStatus: thunk(async (actions, {bookingId, status}) => {

    const response = await bookingService.updateBookingStatus(bookingId, status);
    if (response.succeeded) {
      
      actions.setCurrentBookingStatus(status);
    }
    else {
      // TODO: muestra error en pantalla...
      console.log(response.message)
    }
  }),

  confirmBooking: thunk(async (actions, {bookingId}) => {

    const response = await bookingService.confirmBooking(bookingId);
    if (response.succeeded) {
      actions.removeBookingConfirmation()
    }
    else {
      // TODO: muestra error en pantalla...
      console.log(response.message)
    }
  }),

  rejectPayment: thunk(async (actions, {bookingId, reason}) => {

    const response = await bookingService.rejectBookingPayment(bookingId, reason);
    if (response.succeeded) {
      return response;
    }
    else {
      // TODO: muestra error en pantalla...
      console.log(response.message)
      return null;
    }
  }),

};


// const BookingTemplate = {
//   bookingId: 1,
//   providerId: 1001,
//   info: {
//     serviceName: "Limusina Hummer 1 hora",
//     status: STATUS_REQUESTED,
//     pax: 4,
  
//     startDate: "2021-07-12",
//     startTime: "18:00",
//     endDate: "2021-07-12",
//     endTime: "19:30",
//     weight: 100,
  
//     localization: 1,
//     address: {
//       location: "C/Oslo 4, Sabadell (Barcelona)",
//       flat: "1",
//       door: "C",
//       place: "Donde siempre",
//     },
//     destinationAddress: {
//       location: "C/Oslo 4, Vic (Vic)",
//       flat: "1",
//       door: "C",
//       place: null,
//     },
  
//     hasRemarks: true,
//     remarks: "Este texto es información adicional para explicar la reserva",
//     characteristics: ["Primera", "Segunda"],
//   },
//   tags: ["Javier", "Especial"],
// };

// const BOOKINGS = [1,2,3,4,5,6,7].map(bookingId => ({ ...BookingTemplate, bookingId }));