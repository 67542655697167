import { get, post, remove } from '../../../http';

// const distinct = (array) => {
//   return array.filter((elem, pos, arr) => arr.indexOf(elem) == pos);
// }

class AuthService {

  login = (username, password) => post("/auth/login", { username, password }, null);
  providerSignup = (signId, username, password, social) => post(`/providers/register/${signId}`, { username, password, social: social || "OYDE" }, null);
}

export default new AuthService();