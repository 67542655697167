import { createStore, persist, action } from 'easy-peasy';
import { menuStore, authStore } from './modules/core/store';
import { providersStore } from './modules/providers/store';
import { productStore } from './modules/providers/models/ProductStore';

export const store = createStore({
  menu: menuStore,
  auth: persist(authStore, { storage: "localStorage" }),
  providers: providersStore,
  products: productStore,
});