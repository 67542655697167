import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton, Button, Tooltip, TextField, InputAdornment, Hidden, Switch, FormGroup, FormControl, FormLabel, FormHelperText, FormControlLabel, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { styled } from '@mui/styles';
import { ArrowBack, Business, Store, Phone, AccountBalance } from '@mui/icons-material';
import { useStoreState, useLocalStore, action, thunk, useStoreRehydrated } from 'easy-peasy';
import { makeStyles, useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useHistory } from "react-router-dom";
import { Scrollchor } from 'react-scrollchor';
import providerService from './services/ProviderService';
import Section from '../core/Section';

const BANK_ACCOUNTS_HEADERS = [ "IBAN", "Banco", "Pred." ];
const BANK_ACCOUNTS_FIELDS = [ "iban", "bankEntity", "isDefault"];
const CONTACTS_HEADER = [ "Nombre", "Teléfono", "Mail" ];
const CONTACTS_FIELDS = [ "name", "phone", "email"];

const useStyles = makeStyles((theme) => ({
  item: {
    padding: '8px',
  },
}));

export default function ProviderRegistry() {

  const [values, setValues] = React.useState({
    gilad: true,
    jason: false,
    antoine: true,
  });

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.checked });
  };

  const classes = useStyles();
  const history = useHistory();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

  const [expand, setExpand] = useState({ business: true, store: true, contacts: true, account: true });
  const { session, loggedIn } = useStoreState(state => state.auth );
  const isRehydrated = useStoreRehydrated();
  const [state, actions] = useLocalStore(() => ({

    // STATE
    registry: null,
    loading: true,

    // ACTIONS
    setRegistry: action((_state, registry) => { _state.registry = registry; }),
    setLoading: action((_state, payload) => { _state.loading = payload; }),

    // THUNKS
    getRegistry: thunk(async (_actions, {providerId}) => {
      _actions.setLoading(true);

      const response = await providerService.getProviderRegistry(providerId);
      if (response.succeeded) {

        _actions.setRegistry(response.data);
      }
      else {
        _actions.setRegistry(null);
        // TODO: muestra error en pantalla...
        console.log(response.message)
      }
      _actions.setLoading(false);
    }),

  }), []);

  useEffect(() => {
    if (isRehydrated) {
      const providerId = session.providers[0];
      if (providerId) {
        actions.getRegistry({ providerId });
      }
    }
  }, [isRehydrated]);

  // useEffect(() => { console.log('isRehydrated:', isRehydrated)}, [isRehydrated]);

  const collapseOrExpandAll = (exp) => setExpand({ business: exp, store: exp, contacts: exp, account: exp });

  const inputProps = (icon) => ({ startAdornment: <InputAdornment position="start">{icon}</InputAdornment> });

  const { registry, loading } = state;

  return (
    <>
      <Grid container direction="column" width="100%" style={{ marginLeft: '0', paddingRight: '0' }}>

        <Grid xs={12}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '12px' }}>

            {/* <div style={{ display: 'flex', alignItems: 'center' }}>
              <Hidden only={['md', 'lg', 'xl']}><Typography variant="h6" color="primary">Registro</Typography></Hidden>
              <Hidden only={['xs', 'sm']}><Typography variant="h6" color="primary">Registro de proveedor</Typography></Hidden>
            </div> */}

            <div style={{ display: 'flex', alignItems: 'center', paddingRight: '0px' }}>
              <Scrollchor to="business" target="registry-container" beforeAnimate={_ => {}} afterAnimate={_ => history.goBack()}>
                <Tooltip title="Ver datos fiscales"><IconButton size="medium"><Business /></IconButton></Tooltip>
              </Scrollchor>
              <Scrollchor to="store" target="registry-container" beforeAnimate={_ => {}} afterAnimate={_ => history.goBack()}>
                <Tooltip title="Ver datos comerciales"><IconButton size="medium"><Store /></IconButton></Tooltip>
              </Scrollchor>
              <Scrollchor to="contacts" target="registry-container" beforeAnimate={_ => {}} afterAnimate={_ => history.goBack()}>
                <Tooltip title="Ver contactos"><IconButton size="medium"><Phone /></IconButton></Tooltip>
              </Scrollchor>
              <Scrollchor to="account" target="registry-container" beforeAnimate={_ => {}} afterAnimate={_ => history.goBack()}>
                <Tooltip title="Ver datos bancarios"><IconButton size="medium"><AccountBalance /></IconButton></Tooltip>
              </Scrollchor>
            </div>
            
          </div>
        </Grid>

        <div id="registry-container" style={{ overflowX: 'hidden', overflowY: 'scroll', width: "100%", height: `calc(100vh - 154px)`, margin: 0 }}>

          <Grid container direction="column" xs={12} spacing={isMobile ? 1: 2} width="100%">

            <Grid>
              <div id="business">
                <Section title="DATOS FISCALES" icon={<Business color="primary" />} loading={loading} expand={expand.business} onExpand={exp => setExpand(s => ({ ...s, business: exp }))}>
                  <Grid container direction="column">
                    
                    <Grid xs={12} className={classes.item}>
                      {/* <TextField fullWidth={true} InputLabelProps={{ shrink: true }} InputProps={inputProps(<Business />)}
                        label="Razón social"
                        value={registry?.businessName} 
                      /> */}
                      <TextField variant="standard" fullWidth={true} InputLabelProps={{ shrink: true }}
                        label="Razón social"
                        value={registry?.businessName || ""} 
                      />
                    </Grid>

                    <Grid xs={12} className={classes.item}>
                      <TextField variant="standard" fullWidth={false} InputLabelProps={{ shrink: true }}
                        label="CIF/NIF"
                        value={registry?.fiscalNumber || ""} 
                      />
                    </Grid>

                    <Title text="DIRECCIÓN FISCAL" />

                    <Grid xs={12} className={classes.item}>
                      <TextField variant="standard" fullWidth={true} InputLabelProps={{ shrink: true }}
                        label="Dirección"
                        value={registry?.fiscalAddress?.location || ""} 
                      />
                    </Grid>

                    <Grid container xs={12}>

                      <Grid xs={3} className={classes.item}>
                        <TextField variant="standard" fullWidth={false} InputLabelProps={{ shrink: true }}
                          label="Piso"
                          value={registry?.fiscalAddress?.flat || ""}
                        />
                      </Grid>

                      <Grid xs={3} className={classes.item}>
                        <TextField variant="standard" fullWidth={false} InputLabelProps={{ shrink: true }}
                          label="Puerta"
                          value={registry?.fiscalAddress?.door || ""} 
                        />
                      </Grid>

                      <Grid xs={6} className={classes.item}>
                        <TextField variant="standard" fullWidth={true} InputLabelProps={{ shrink: true }}
                          label="Ciudad"
                          value="" 
                        />
                      </Grid>

                    </Grid>

                    <Grid container xs={12}>

                      <Grid xs={8} className={classes.item}>
                        <TextField variant="standard" fullWidth={true} InputLabelProps={{ shrink: true }}
                          label="País"
                          value=""
                        />
                      </Grid>

                      <Grid xs={4} className={classes.item}>
                        <TextField variant="standard" fullWidth={false} InputLabelProps={{ shrink: true }}
                          label="CP"
                          value=""
                        />
                      </Grid>

                    </Grid>

                  </Grid>
                </Section>
              </div>
            </Grid>

            <Grid>
              <div id="store">
                <Section title="DATOS COMERCIALES" icon={<Store color="primary" />} loading={loading} expand={expand.store} onExpand={exp => setExpand(s => ({ ...s, store: exp }))}>
                  <Grid container direction="column">
                    
                    <Grid xs={12} className={classes.item}>
                      <TextField variant="standard" fullWidth={true} InputLabelProps={{ shrink: true }}
                        label="Nombre comercial"
                        value={registry?.tradeName || ""} 
                      />
                    </Grid>

                    <Title text="DIRECCIÓN COMERCIAL" />

                    <Grid xs={12} className={classes.item}>
                      <TextField variant="standard" fullWidth={true} InputLabelProps={{ shrink: true }}
                        label="Dirección"
                        value={registry?.fiscalAddress?.location || ""} 
                      />
                    </Grid>

                    <Grid container xs={12}>

                      <Grid xs={3} className={classes.item}>
                        <TextField variant="standard" fullWidth={false} InputLabelProps={{ shrink: true }}
                          label="Piso"
                          value={registry?.fiscalAddress?.flat || ""} 
                        />
                      </Grid>

                      <Grid xs={3} className={classes.item}>
                        <TextField variant="standard" fullWidth={false} InputLabelProps={{ shrink: true }}
                          label="Puerta"
                          value={registry?.fiscalAddress?.door || ""} 
                        />
                      </Grid>

                      <Grid xs={6} className={classes.item}>
                        <TextField variant="standard" fullWidth={true} InputLabelProps={{ shrink: true }}
                          label="Ciudad"
                          value="" 
                        />
                      </Grid>

                    </Grid>

                    <Grid container xs={12}>

                      <Grid xs={8} className={classes.item}>
                        <TextField variant="standard" fullWidth={true} InputLabelProps={{ shrink: true }}
                          label="País"
                          value=""
                        />
                      </Grid>

                      <Grid xs={4} className={classes.item}>
                        <TextField variant="standard" fullWidth={false} InputLabelProps={{ shrink: true }}
                          label="CP"
                          value=""
                        />
                      </Grid>

                    </Grid>
                  
                  </Grid>
                </Section>
              </div>
            </Grid>

            <Grid>
              <div id="contacts">
                <Section title="CONTACTOS" icon={<Phone color="primary" />} loading={loading} expand={expand.contacts} onExpand={exp => setExpand(s => ({ ...s, contacts: exp }))}>
                  <Grid container direction="column">

                    <Title text="GESTIÓN DE RESERVAS" />
                    <div style={{ padding: '0 8px 20px 8px' }}>
                      <FormTable header={CONTACTS_HEADER} fields={CONTACTS_FIELDS} items={registry?.contacts} onItemSelected={(item, index) => {console.log(index, item)}} />
                    
                      <Grid container justify="flex-end" style={{ marginTop: '5px' }}>
                        <Button variant="outlined" color="primary">Añadir</Button>
                      </Grid>  
                    </div>
                        
                    
                    <Title text="PERSONAS DE CONTACTO" />
                    <div style={{ padding: '0 8px 20px 8px' }}>
                      <FormTable header={CONTACTS_HEADER} fields={CONTACTS_FIELDS} items={registry?.bookingContacts} onItemSelected={(item, index) => {console.log(index, item)}} />

                      <Grid container xs={12} style={{ marginTop: '24px' }}>

                        <Grid xs={8} className={classes.item}>
                          <TextField variant="standard" fullWidth={true} InputLabelProps={{ shrink: true }}
                            label="Nombre"
                            value=""
                          />
                        </Grid>

                        <Grid xs={4} className={classes.item}>
                          <TextField variant="standard" fullWidth={true} InputLabelProps={{ shrink: true }}
                            label="Teléfono"
                            value=""
                          />
                        </Grid>

                      </Grid>

                      <Grid xs={12} className={classes.item}>
                        <TextField variant="standard" fullWidth={true} InputLabelProps={{ shrink: true }}
                          label="Mail"
                          value="" 
                        />
                      </Grid>

                      <Grid container justify="space-between" style={{ marginTop: '5px' }}>
                        <Grid>
                          <Button variant="outlined" color="secondary">Eliminar</Button>
                        </Grid>
                        <Grid>
                          <Button variant="text">Cancelar</Button>
                          <Button variant="outlined" color="primary" style={{ marginLeft: '8px' }}>Aceptar</Button>
                        </Grid>
                      </Grid>
                    </div>

                  </Grid>
                </Section>
              </div>
            </Grid>

            <Grid>
              <div id="account" style={{ minHeight: '100vh' }}>
                <Section title="DATOS BANCARIOS" icon={<AccountBalance color="primary" />} loading={loading} expand={expand.account} onExpand={exp => setExpand(s => ({ ...s, account: exp }))}>
                  <Grid container direction="column">
                  
                    <FormControl component="fieldset">
                      {/* <FormLabel component="legend">Assign responsibility</FormLabel> */}
                      <FormHelperText>Métodos de pago</FormHelperText>
                      <FormGroup>
                        <FormControlLabel
                          control={<Switch color="primary" checked={values.gilad} onChange={handleChange} name="gilad" />}
                          label="Efectivo"
                        />
                        <FormControlLabel
                          control={<Switch color="primary" checked={values.jason} onChange={handleChange} name="jason" />}
                          label="Transferencia"
                        />
                        <FormControlLabel
                          control={<Switch color="primary" checked={values.antoine} onChange={handleChange} name="antoine" />}
                          label="Tarjeta"
                        />
                      </FormGroup>
                    </FormControl>

                    <Title text="CUENTAS BANCARIAS" />

                    <FormTable header={BANK_ACCOUNTS_HEADERS} fields={BANK_ACCOUNTS_FIELDS} items={[]} onItemSelected={(item, index) => {console.log(index, item)}} />

                  </Grid>
                </Section>
              </div>
            </Grid>

          </Grid>

        </div>
      </Grid>
    </>
  );
}




function Title({text}) {
  return (
    <div style={{ margin: '4px 8px', backgroundColor: '#6C6C6C', borderRadius: '3px' }}>
      <Typography style={{ margin: '2px 4px', fontWeight: 'bold', color: 'white' }}>{text}</Typography>
    </div>
  );
}



const useTableStyles = makeStyles((theme) => ({
  row: {
    cursor: 'pointer',
  },
  head: {
    // backgroundColor: '#6C6C6C',
  },
  headCell: {
    // color: 'white',
    // fontSize: 'bold',
  }
}));


function FormTable({ header, fields, items, onItemSelected }) {

  const classes = useTableStyles();

  const [selectedIndex, setSelectedIndex] = useState();

  useEffect(() => {
    setSelectedIndex();
  }, [items]);

  // const rows = items.map(i => ({ item: i, columns: Object.keys(i).map(k => i[k]) }));
  const rows = items.map(i => ({ item: i, columns: fields.map(k => i[k]) }));

  const onRowSelected = (event, item, index) => {
    setSelectedIndex(index);
    onItemSelected(item, index);
  }

  return (
    <TableContainer component={Box}>
      <Table aria-label="simple table" size="small">
      <TableHead className={classes.head}>
          <TableRow>
            {header.map((title, idx) => (<TableCell key={idx} className={classes.headCell}>{title.toUpperCase()}</TableCell>))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, idx) => (
            <TableRow key={idx} className={classes.row} hover selected={idx === selectedIndex} onClick={(event) => onRowSelected(event, row.item, idx)}>
              {row.columns.map((column, columnIdx) => (
                <TableCell key={columnIdx} component="th" scope="row">{column}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}














/*
export interface IRegistry {

  providerType: number; // Tipo de proveedor (0 - Persona individual, 1 - Empresa)
  businessName: string; // Razón social
  tradeName: string; // Nombre comercial
  fiscalNumber: string; // NIF/CIF
  entryDate: Date; // Fecha de alta
  active: boolean; // Activo

  fiscalAddress: IRegistryAddress; // Dirección fiscal
  serviceAddress: IRegistryAddress; // Dirección de servicio

  cashPayment: boolean; // Pago en efectivo
  bankTransferPayment: boolean; // Pago por transferencia bancaria
  cardPayment: boolean; // Pago en tarjeta
  paymentMethod: number; // Modo de pago (1 - Cash, 2 - BankTransfer, 3 - Card)

  groupId: number; // Identificador del grupo
  groupName: string; // Nombre del grupo

  contacts: IContact[]; // Personas de contacto
  bookingContacts: IContact[]; // Personas de reserva

  bankAccounts: IBankAccount[]; // Cuentas bancarias
}

export interface IRegistryAddress {

  location: string;
  flat: number;
  door: number;
}

export interface IContact {

  id: number
  // contactType: number; // Tipo de contacto: (1 - Persona de reserva, 2 - Persona de contacto)

  name: string;
  phone: string;
  email: string;
  position: string;
}

export interface IBankAccount {

  id: number

  bankEntity: string;
  iban: string;
  isDefault: boolean;
}
*/



/*
import React, { useState } from 'react';
import { AppBar, Box, Typography, Tab, Tabs } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Business, Store, Phone, AccountBalance } from '@mui/icons-material';
import FiscalForm from './registry/FiscalForm';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

export default function ScrollableTabsButtonForce() {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          <Tab label={<div style={{ display: 'flex', alignItems: 'center' }}><Business /><span style={{ marginLeft: '15px' }}>Fiscal</span></div>} {...a11yProps(0)} />
          <Tab label={<div style={{ display: 'flex', alignItems: 'center' }}><Store /><span style={{ marginLeft: '15px' }}>Comercial</span></div>} {...a11yProps(1)} />
          <Tab label={<div style={{ display: 'flex', alignItems: 'center' }}><Phone /><span style={{ marginLeft: '15px' }}>Contactos</span></div>} {...a11yProps(2)} />
          <Tab label={<div style={{ display: 'flex', alignItems: 'center' }}><AccountBalance /><span style={{ marginLeft: '15px' }}>Datos Bancarios</span></div>} {...a11yProps(3)} />

          {/* <Tab label="Fiscal" icon={<Business />} {...a11yProps(0)} />
          <Tab label="Comercial" icon={<Store />} {...a11yProps(1)} />
          <Tab label="Contactos" icon={<Phone />} {...a11yProps(2)} />
          <Tab label="Datos Bancarios" icon={<AccountBalance />} {...a11yProps(3)} /> 
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <FiscalForm />
      </TabPanel>
      <TabPanel value={value} index={1}>
        Item Two
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel>
      <TabPanel value={value} index={3}>
        Item Four
      </TabPanel>
    </div>
  );
}
*/








