export const Paths = {
  LOGIN: "/login",
  SIGNUP: "/signup/:signId",
  PROVIDERS: "/providers",
  PROVIDERS_BOOKINGS: "/providers/bookings",
  PROVIDERS_AVAILABILITIES: "/providers/availabilities",
  PROVIDERS_PAYMENTS: "/providers/payments",
  PROVIDERS_REGISTRY: "/providers/registry",
  PROVIDERS_PRODUCTS: "/providers/products",
  PROVIDERS_PROPOSALS: "/providers/products/proposal",
} 