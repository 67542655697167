import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TextField, InputAdornment, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { Alert } from '@mui/lab';
import { PeopleAlt, Euro, Payment as PaymentIcon } from '@mui/icons-material';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { makeStyles } from '@mui/styles';
import { formatWithOptions } from 'date-fns/fp';
import { es } from 'date-fns/locale';
import Section from '../core/Section';
import PaymentStatus from './PaymentStatus';

const dateFormatter = formatWithOptions({ locale: es }, 'd LLL yyyy');
const euroFormatter = new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format;

const useTableStyles = makeStyles((theme) => ({
  row: {
    cursor: 'pointer',
  },
  head: {
    // backgroundColor: '#6C6C6C',
  },
  headCell: {
    // color: 'white',
    // fontSize: 'bold',
  }
}));

const TRANSACTION_TYPES = ["Coste servicio", "Anticipado", "Pagado en el sitio", "Pagado por el cliente", "Liquidado"];

function Title({text}) {
  return (
    <div style={{ margin: '4px 8px', backgroundColor: '#6C6C6C', borderRadius: '3px' }}>
      <Typography style={{ margin: '2px 4px', fontWeight: 'bold', color: 'white' }}>{text}</Typography>
    </div>
  );
}

const BookingPayment = ({bookingId, expand, onExpand, paymentError, lite}) => {
  
  const classes = useTableStyles();

  const { currentPayment: payment, paymentLoading } = useStoreState((state) => state.providers);
  const { fetchBookingPayment, rejectPayment } = useStoreActions((state) => state.providers);
  const [selectedIndex, setSelectedIndex] = useState();
  const [open, setOpen] = useState(false);
  const [rejectReason, setRejectReason] = useState();
  const [rejecting, setRejecting] = useState(false);

  useEffect(() => { if(bookingId) fetchBookingPayment(bookingId); }, [bookingId]);

  const onRowSelected = (event, item, index) => {
    // setSelectedIndex(index);
  }

  const handleClose = () => {
    setRejectReason();
    setOpen(false);
  };

  const onReject = () => {
    setOpen(true);
  }

  const onRejectConfirmed = async () => {
    setRejecting(true);
    const result = await rejectPayment({ bookingId, reason: rejectReason });
    setRejecting(false);
    setOpen(false);

    fetchBookingPayment(bookingId);
  }

  const peopleText = payment?.pax === 1 ? "persona" : "personas";
  const pendingText = payment?.pendingAmount > 0 ? "A cobrar" : "A pagar";
  const pendingValue = Math.abs(payment?.pendingAmount || 0);

  return (
    <>
      <Section title="INFORMACIÓN DE PAGO" icon={<PaymentIcon />} loading={paymentLoading} expand={expand} onExpand={onExpand} extra={<PaymentStatus payment={payment} />} lite={lite}>
        
        {paymentError ? 
          <Alert severity="error">{paymentError}</Alert> :
        
          <>
            {payment == null && <Grid>
              <div style={{ marginLeft: '8px' }}>
                <Typography variant="subtitle1">No hay información de pago</Typography>
              </div>
            </Grid>}

            {payment != null && <div>
              <Grid container direction="column">

              {payment != null && payment.status === 0 && <Grid container item direction="row" justify="flex-end" alignItems='center'>

                <Grid item>
                  <Typography variant="subtitle2" style={{ color: 'gray', verticalAlign: 'center' }}>Rechazar información de pago</Typography>
                </Grid>

                <Grid item>
                  <Button color="secondary" variant="outlined" size="small" style={{ marginLeft: '8px', marginRight: '8px' }} onClick={onReject}>Rechazar</Button>
                </Grid>

              </Grid>}

                {/* {payment != null && payment.status === 0 && <Grid container item direction="row" justify="flex-end">

                  <Grid container item md={8} xs={12} justify="flex-end" alignContent="center">
                    <Typography style={{ color: 'gray' }}>Rechazar información de pago</Typography>
                  </Grid>

                  <Grid item>
                    <Button color="secondary" variant="outlined" style={{ marginLeft: '16px' }} onClick={onReject}>Rechazar</Button>
                  </Grid>

                </Grid>} */}

                {payment != null && payment.status === 1 && <Grid>
                  <Grid container item md={12} xs={12} justify="flex-start" alignContent="center">
                    <Typography style={{ color: '#E84C3D' }}>Has rechazado la información de pago</Typography>
                  </Grid>
                </Grid>}

                <Grid container item direction="row" style={{ marginTop: '8px' }}>
                  <Grid item md={6} xs={12}>
                    <DetailItem icon={<PeopleAlt />} title="Número asistentes final" content={`${payment?.pax} ${peopleText}`} />
                  </Grid>

                  <Grid item md={6} xs={12}>
                    <DetailItem icon={<Euro />} title={pendingText} content={pendingValue} />
                  </Grid>
                </Grid>
              
                <Grid item>
                  <Title text="HISTÓRICO DE TRANSACCIONES" />
                  <TableContainer component={Box}>
                    <Table aria-label="simple table" size="small">
                    <TableHead className={classes.head}>
                        <TableRow>
                          <TableCell key="dat" className={classes.headCell}>FECHA</TableCell>
                          <TableCell key="trn" className={classes.headCell}>TRANSACCIÓN</TableCell>
                          <TableCell key="qty" className={classes.headCell} align="right">IMPORTE</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {payment.transactions.map((transaction, idx) => (
                          <TableRow key={idx} className={classes.row} hover selected={idx === selectedIndex} onClick={(event) => onRowSelected(event, transaction, idx)}>
                            <TableCell key="ddat" component="th" scope="row">{dateFormatter(new Date(transaction.TransactionDate))}</TableCell>
                            <TableCell key="dtrn" component="th" scope="row">{TRANSACTION_TYPES[transaction.type]}</TableCell>
                            <TableCell key="dqty" component="th" scope="row" align="right">{euroFormatter(transaction.amount)}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>

              </Grid>
            </div>}
          </>
        }
      </Section>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Rechazar pago</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Especifica un motivo por el que rechazas la información de pago
          </DialogContentText>
          <TextField
            autoFocus InputLabelProps={{ shrink: true }} multiline rows={5}
            margin="dense"
            id="name"
            label="Motivo"
            type="email"
            fullWidth
            value={rejectReason}
            onChange={e => setRejectReason(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>Cancelar</Button>
          <Button color="secondary" variant="contained" disabled={rejecting || !rejectReason} onClick={onRejectConfirmed}>Rechazar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default BookingPayment;


function DetailItem({icon, title, content}) {
  return (
    <div style={{ padding: '8px' }}>
      <TextField variant='standard' fullWidth={true} InputLabelProps={{ shrink: true }} multiline InputProps={{ startAdornment: 
        <InputAdornment position="start">
          {icon}
        </InputAdornment> }}
        label={title}
        value={content} 
        
      />
    </div>
  );
}