import { action, computed, thunk } from 'easy-peasy';
// import { formatWithOptions } from 'date-fns/fp';
// import { es } from 'date-fns/locale';
import productService from '../services/ProductService';

// const dateFormatter = formatWithOptions({ locale: es }, 'yyyy-MM-dd');

export const productStore = {

  products: [],
  product: undefined,
  filter: {
    text: undefined,
    category: undefined,
  },
  filteredProducts: [],
  realProducts: computed(state => state.products.filter(p => p.status === 'PRODUCT')),

  error: undefined,
  loading: false,
  dirty: false,


  setProducts: action((state, products) => state.products = products),
  setProduct: action((state, product) => state.product = product),
  setFilter: action((state, filter) => { 
    state.filter = filter;
    state.filteredProducts = filterProducts(state.products, filter.category);
  }),
  setFilteredProducts: action((state, products) => state.filteredProducts = products),
  setError: action((state, error) => state.error = error),
  setLoading: action((state, loading) => state.loading = loading),
  setDirty: action((state, dirty) => state.dirty = dirty),
  updateProduct: action((state, product) => {
    state.product = product;
    state.dirty = true;
  }),
  clearProduct: action((state) => {
    state.product = undefined;
    state.dirty = false;
    state.error = undefined;
  }),

  fetchProduct: thunk(async (actions, productId) => {
    actions.setLoading(true);
    const product = await productService.getProduct(productId);
    if (product) {

      product.ui = { new: false };

      actions.setProduct(product);
      actions.setDirty(false);
      actions.setError(undefined);
    }
    actions.setLoading(false);
  }),

  generateEmptyProposal: thunk(async (actions) => {
    actions.setLoading(true);
    const product = await productService.generateEmptyProposal();
    if (product) {

      product.ui = { new: true }; // Adds a flag to indicate that this is a new proposal

      actions.setProduct(product);
      actions.setDirty(false);
      actions.setError(undefined);
    }
    actions.setLoading(false);
    return product?.proposal?.proposalId;
  }),


  fetchProposal: thunk(async (actions, proposalId) => {
    actions.setLoading(true);
    const product = await productService.getProposal(proposalId);
    if (product) {

      product.ui = { new: false };

      actions.setProduct(product);
      actions.setDirty(false);
      actions.setError(undefined);
    }
    actions.setLoading(false);
  }),

  loadProducts: thunk(async (actions, { force }, {getState}) => {
    const { filter, products } = getState();

    if (!force && products.length > 0) return;

    actions.setLoading(true);

    const [props, prods] = await Promise.all([
      productService.getProposals(),
      productService.getProducts(),
    ]);

    // TODO: Take into account filter.text?

    const items = [ ...props, ...prods ].map(p => ({ ...p, ui: { new: false } }));
    const filteredItems = filterProducts(items, filter.category);

    actions.setProducts(items);
    actions.setFilteredProducts(filteredItems);
    actions.setLoading(false);
  }),

  saveProposal: thunk(async (actions, _, {getState}) => {
    const { product } = getState();
    if (!product.proposal) return;

    let error = undefined;
    actions.setLoading(true);

    if (product.ui.new) {

      const request = {
        providerId: product.providerId,
        proposal: product.proposal,
        attachments: product.attachments || [],
      };
      const res = await productService.createProposal(request);
      if (res.ok) {
        console.log('Proposal created:', res.data);
        const proposalId = res.data;
        const savedProduct = { ...product, ui: { new: false }, proposal: { ...product.proposal, proposalId } };
        
        actions.setProduct(savedProduct);
        actions.setDirty(false);

        // Add to the list of proposals
        // actions.setProducts([ ...getState().products, savedProduct ]);
        // await actions.loadProducts({ force: true });
      }
      else error = res.error;
    }
    else {
      const request = {
        providerId: product.providerId,
        proposal: product.proposal,
        attachments: product.attachments || [],
      };
      const res = await productService.updateProposal(request);
      if (res.ok) {
        actions.setDirty(false);

        // Update the list of proposals with the new data
        // const items = getState().products.map(p => p.proposal?.proposalId === product.proposal.proposalId ? product : p);
        // actions.setProducts(items);
        // await actions.loadProducts({ force: true });
      }
      else error = res.error; 
    }
    actions.setLoading(false);
    actions.setError(error);
  }),

  removeProposal: thunk(async (actions, proposalId, {getState}) => {
    console.log('Removing proposal:', proposalId);
    actions.setLoading(true);
    const res = await productService.deleteProposal(proposalId);
    if (res.ok) {
      console.log('Proposal removed:', proposalId);
      // const items = getState().products.filter(p => p.proposal?.proposalId !== proposalId);
      // actions.setProducts(items);
      // await actions.loadProducts({ force: true });
    }
    actions.setLoading(false);
  }),

  reviewProposal: thunk(async (actions, proposalId) => {
    actions.setLoading(true);
    const res = await productService.reviewProposal(proposalId);
    if (res.ok) {
      console.log('Proposal reviewed:', proposalId);
      // const items = getState().products.map(p => p.proposal?.proposalId === proposalId ? { ...p, status: 'REVIEWING' } : p);
      // actions.setProducts(items);
      // await actions.loadProducts({ force: true });
    }
    actions.setLoading(false);
  }),

  confirmProduct: thunk(async (actions, productId) => {
    actions.setLoading(true);
    const res = await productService.confirmProduct(productId);
    if (res.ok) {
      console.log('Product confirmed:', productId);
      // const items = getState().products.map(p => p.productId === productId ? { ...p, status: 'VALIDATED' } : p);
      // actions.setProducts(items);
      // await actions.loadProducts({ force: true });
    }
    actions.setLoading(false);
  }),
};

function filterProducts (items, category) {
  if (category === 'PROPOSAL') {
    return items.filter(p => p.status === 'PROPOSAL');
  }
  else if (category === 'REVIEWING') {
    return items.filter(p => p.status === 'REVIEWING');
  }
  else if (category === 'REJECTED') {
    return items.filter(p => p.status === 'REJECTED');
  }
  else if (category === 'VALIDATED') {
    return items.filter(p => p.status === 'VALIDATED');
  }
  return items;
}


const FAKE_PRODUCT = {
  providerId: 0,
  status: "PROPOSAL",
  createdAt: new Date(),
  updatedAt: new Date(),

  attachments: [
    "attachment1.jpg",
  ],

  proposal: undefined,
  data: {
    productId: 0,

    validatedAt: new Date(),
  
    name: "Producto A",
    title: "Nombre del producto asignado por OYDE",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  
    duration: "De 15 a 60 minutos",
  
    included: [
      "Se incluye 1 consumición por persona",
      "Toda la equipación necesaria",
      "Seguro de responsabilidad civil",
      "Todos los integrantes del grupo deben llevar calzado deportivo",
    ],
    considerations: [
      "Los menores de 18 años deben ir acompañados de un adulto. Los menores de 18 años deben ir acompañados de un adulto Los menores de 18 años deben ir acompañados de un adulto",
      "En caso de lluvia, se pospondrá la actividad",
      "Se permite el acceso a personas con movilidad reducida",
    ],
  
    image: undefined,
    
    options: [
      {
        name: "Opción A",
        price: 15,
        PriceType: "GROUP",
        minPax: 20,
        maxPax: 0,
      },
      {
        name: "Opción B",
        price: 20,
        PriceType: "PERSON",
        minPax: 5,
        maxPax: 10,
      },
    ],
  }
};
