import { get, post, remove, put } from '../../../http';
import { store } from '../../../store';

const UNKNOWN_ERROR = { code: 0, message: 'Error desconocido', succeeded: false };

class ProviderService {

  // REGISTRY
  getProviderRegistry = (id) => get(`/providers/${id}`, UNKNOWN_ERROR);
}

function getProvider() {
  return store.getState().auth.providerId || 0;
}

export default new ProviderService();