const Paths = {
  HOME: "/providers",
  BOOKINGS: "/providers/bookings",
  BOOKINGS_LIST: "/providers/bookings/list",
  BOOKINGS_CALENDAR: "/providers/bookings/calendar",
  BOOKINGS_SCHEDULE: "/providers/bookings/schedule",

  AVAILABILITIES: "/providers/availabilities",
  AVAILABILITIES_LIST: "/providers/availabilities/list",

  PRODUCTS: "/providers/products",
  PRODUCTS_LIST: "/providers/products/list",
  PRODUCTS_PROPOSAL: "/providers/products/proposal",

  PAYMENTS: "/providers/payments",
  REGISTRIES: "/providers/registry",

  MESSAGES: "/providers/messages",
  CONTRACTS: "/providers/contracts",
  ISSUES: "/providers/issues",
  TEAM: "/providers/team",
  SETTINGS: "/providers/settings",
}

export default Paths;