import { useEffect } from 'react';
import { Box, Grid, useMediaQuery, Button, Stack, Divider, Typography } from '@mui/material';
import { SentimentDissatisfiedOutlined } from '@mui/icons-material';
import { makeStyles, useTheme } from '@mui/styles';
import { useHistory } from "react-router-dom";
import { useStoreState, useStoreActions } from 'easy-peasy';
import ProductCard from './ProductCard';
import Empty from '../core/Empty';
import { Paths } from '../../constants';

export default function ProductList() {

  const history = useHistory();
  
  const { filteredProducts, realProducts, loading } = useStoreState((state) => state.products);
  const { loadProducts, generateEmptyProposal } = useStoreActions((state) => state.products);

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

  useEffect(() => {
    loadProducts({ force: false });
  }, []);

  const onNewProduct = async () => { 
    const proposalId = await generateEmptyProposal();
    if (proposalId) {
      history.push(`${Paths.PROVIDERS_PROPOSALS}/${proposalId}`);
    }
  }

  return (
    <Box sx={{ flex: 1, mt: 0, p: 2, pt: 0 }}>

      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ p: 1, pb: 3, backgroundColor: 'white' }}>
        <Stack direction="row" spacing={3} alignItems="center">
          <Typography variant="subtitle1" style={{ color: '#5B5B5B' }}>{realProducts?.length || 0} productos en catálogo</Typography>
          <Divider orientation="vertical" flexItem />
          <ProductsFilter />
        </Stack>
        <Button variant="contained" color="primary" onClick={onNewProduct}>Crear producto</Button>
      </Box>

      

      {(filteredProducts?.length || 0) > 0 ? (
        <Grid item container pb={1} pr={1} spacing={isMobile? 1 : 3} wrap="wrap" alignContent='flex-start' className={classes.contentArea}>
          {filteredProducts.map((product, idx) => (
            <ProductCard key={idx} product={product} />
          ))}
        </Grid>
        ) : (
          <>
          {!loading && <Empty title="No se han encontrado ítems" subtitle="Cambia el filtro o crea una nueva propuesta de producto" />}
          </>
        )
      }

      {/* {!totalBookings && !loading && <Grid item container justify="center" style={{ paddingTop: '50px' }}>
        <Typography variant="h5" color="primary">Sin resultados</Typography>
      </Grid>} */}

      {/* {loading && <Grid item container justify="center" style={{ paddingTop: '50px' }}>
        <CircularProgress color="inherit" />
      </Grid>} */}






    </Box>
  );
}

function ProductsFilter() {

  const { filter } = useStoreState((state) => state.products);
  const { setFilter } = useStoreActions((state) => state.products);

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <FilterButton category="PROPOSAL" value={filter.category} onChange={c => setFilter({ ...filter, category: c ? 'PROPOSAL' : undefined })} />
      <FilterButton category="REVIEWING" value={filter.category} onChange={c => setFilter({ ...filter, category: c ? 'REVIEWING' : undefined })} />
      <FilterButton category="REJECTED" value={filter.category} onChange={c => setFilter({ ...filter, category: c ? 'REJECTED' : undefined })} />
      <FilterButton category="VALIDATED" value={filter.category} onChange={c => setFilter({ ...filter, category: c ? 'VALIDATED' : undefined })} />

      {/* <Button variant={category === 'PROPOSALS' ? 'outlined' : 'text' } color="info" sx={{ textTransform: 'none' }}>Propuestas</Button>
      <Button variant="text" color="info" sx={{ textTransform: 'none' }}>En revisión</Button>
      <Button variant="text" color="info" sx={{ textTransform: 'none' }}>Rechazadas</Button>
      <Button variant="text" color="info" sx={{ textTransform: 'none' }}>Validadas</Button> */}
    </Stack>
  );
}

const filterTitles = {
  'PROPOSAL': 'Borradores',
  'REVIEWING': 'En revisión',
  'REJECTED': 'Rechazadas',
  'VALIDATED': 'Validadas',
}

function FilterButton({ category, value, onChange }) {
  return (
    <Button variant={category === value ? 'outlined' : 'text' } color="info" sx={{ textTransform: 'none', minWidth: '115px' }}
      onClick={() => onChange(!(category === value))}>
      {filterTitles[category]}
    </Button>
  );
}

const useStyles = makeStyles((theme) => ({
  contentArea: {
    // xs={12} sm={6} md={6} lg={2}
    // width: '100%',
    overflow: 'scroll',
    [theme.breakpoints.only('xs')]: {
      height: 'calc(100vh - 120px)', 
    },
    [theme.breakpoints.between('sm', 'md')]: {
      height: 'calc(100vh - 290px)',
    },
    [theme.breakpoints.only('lg')]: {
      height: 'calc(100vh - 170px)',
    },
  },
}));