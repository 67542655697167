import React, { useEffect, useState } from 'react';
import { Eventcalendar, localeEs } from '@mobiscroll/react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Box } from '@mui/material';
import bookingService from './services/BookingService';
import { STATUS_ACCEPTED, STATUS_REJECTED } from './BookingState';

const options = {
  schedule: {
    type: 'day',
    startDay: 1, // Monday
    endDay: 7, // Sunday
    startTime: '00:00',
    endTime: '23:59',
  }
};

export default function BookingsScheduler() {

  const { bookings, totalBookings, bookingsFilter: filter } = useStoreState((state) => state.providers);
  const { fetchBookings, setBookingsFilter, resetBookingsFilter, emptyBookingList } = useStoreActions((state) => state.providers);

  const [data, setData] = useState([]);

  useEffect(() =>  {
    async function loadBookings() {

      // const filter = {text , status, confirm, fromDate, toDate, page, pageSize}
      const filter = { page: 1, pageSize: 1000};

      const response = await bookingService.getBookings(filter);
      if (response.succeeded) {
        // console.log(response.data.items)
        const data = response.data.items.map(b => {
          return {
            id: b._id,
            title: b.info.serviceName,
            start: new Date(b.info.startDate + 'T' + b.info.startTime),
            end: new Date(b.info.endDate + 'T' + b.info.endTime),
            allDay: false,
            color: b.info.status === STATUS_ACCEPTED ? '#4caf50' : (b.info.status === STATUS_REJECTED ? '#f44336' : '#2196f3'),
            data: b
          }
        });
        // console.log(data);
        setData(data);
      }
    } 
    loadBookings();
  }, []);

  return (
    <Box display="flex" flexDirection="column" flexGrow={1} sx={{ height: '90vh' }}>
      <Eventcalendar theme="material" locale={localeEs} view={options} data={data} defaultSelectedDate={new Date(2022, 7, 3)} />
    </Box>
  );
}


// setBookingsFilter({ ...filter, 
//   text: "",
//   status: (status != null) ? status : -1, 
//   fromDate: fromDate || "", 
//   toDate: toDate || "",
//   page: 1,
//   confirm: (confirm != null) ? Boolean(confirm) : null,
//   enabled: true,
// });