import { useState } from 'react';
import { Typography, TextField, InputAdornment, Box, Chip, Avatar, Stack, Divider, Tooltip } from '@mui/material';
import { ListAlt as DetailsIcon, LabelOutlined, AccessTime, NoPhotographyOutlined, People, Euro } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import HTMLRenderer from 'react-html-renderer'
import Section from '../core/Section';
import ProductStatus from './ProductStatus';
import { Title, DetailItem } from '../core/Forms';
import { TagList } from './ProposalEditor';
import fileService from '../core/services/FileService';


export default function ProductOverview({ product }) {

  const [expanded, setExpanded] = useState(true);
  const onExpand = (exp) => setExpanded(exp);

  const lite = true;
  const data = product?.data;

  const imageUrl = data?.image ? fileService.getPublicUrl(data.image) : undefined;

  return (
    <Section title="DETALLES" icon={<DetailsIcon />} loading={false} expand={expanded} onExpand={onExpand}
      extra={<ProductStatus product={product} />} lite={lite}>

      <Grid container direction="column">

          {/* <Grid xs={12}>
            <Title text="GENERAL" />
          </Grid> */}

          <Grid container xs={12}>
            <Grid xs={6}>
              <Grid xs={12}>
                <DetailItem icon={<LabelOutlined />} title="Nombre" content={data?.title || data?.name} />
              </Grid>
            
              <Grid xs={12}>
                <DetailItem icon={<AccessTime />} title="Duración" content={data?.duration} />
              </Grid>

              <Grid xs={12}>
                {/* <span style={{ fontSize: '12px', color: '#757575', marginLeft: '8px', marginTop: '8px' }}>Opciones</span> */}
                  
                <Title text="OPCIONES" />
          
                <div style={{ margin: '8px', padding: '8px' }}>
                  <Stack direction="column" spacing={1} divider={<Divider orientation="vertical" flexItem />}>
                    {(data?.options || []).map((option, idx) => (
                      <OptionView key={idx} option={option} />
                    ))}
                  </Stack>
                </div>
              </Grid>
            </Grid>

            <Grid xs={6} display="flex" justifyContent="end" alignItems="start">
              <Box display="flex" justifyContent="center" alignItems="center" sx={{ width: '340px', height: '191px', backgroundColor: 'lightgray', borderRadius: 1, m: 2 }}>
                {/* <NoPhotographyOutlined sx={{ fontSize: '60px', color: 'white'}} /> */}
                {!imageUrl ? 
                (
                  <NoPhotographyOutlined sx={{ fontSize: '60px', color: 'white'}} />
                ) : (
                  // <img src={imageUrl} alt={product.name} style={{ width: '150px', height: '150px' }} />
                    <img 
                      src={imageUrl} 
                      alt={data?.name} 
                      style={{ 
                      width: '340px', 
                      height: '191px', // 340px * 9 / 16 = 191.25px, rounded to 191px
                      objectFit: 'cover', 
                      objectPosition: 'center', 
                      borderRadius: '4px',
                      border: '1px solid #E0E0E0',
                      }} 
                    />
                    )
                  }
                  </Box>
                </Grid>
                </Grid>

                <Grid xs={12}>
                <Title text="DESCRIPCIÓN" />
                {/* <DetailItem title="" content={data?.description} /> */}
            <Box sx={{ p: 2, pt: 1, pb: 1, fontSize: '14px' }}>
              <HTMLRenderer html={data?.description?.replace(/(?:\r\n|\r|\n)/g, '<br>')} />
            </Box>
          </Grid>

          {/* <Grid xs={12}>
            <Title text="OTROS" />
          </Grid> */}

          {/* <Grid xs={12}>
            {data?.included.length > 0 && <>
              <span style={{ fontSize: '14px', fontWeight: 'bold', color: '#1876D2', marginLeft: '16px' }}>Qué incluye</span>
              <div style={{ margin: '8px' }}>
                {data.included.map((text, idx) => (
                  <Tooltip key={idx} title={text}>
                    <Chip style={{ margin: '3px 10px 3px 0px', maxWidth: '400px' }}
                      variant="default"
                      size="small"
                      avatar={<Avatar>{idx + 1}</Avatar>}
                      label={text}
                    />
                  </Tooltip>
                ))}
              </div>
            </>}
          </Grid> */}
          <Grid xs={12}>
            <TagList title="QUÉ INCLUYE" values={data?.included || []} />
          </Grid>

          {/* <Grid xs={12}>
            {data?.considerations.length > 0 && <>
              <span style={{ fontSize: '14px', fontWeight: 'bold', color: '#1876D2', marginLeft: '16px' }}>A tener en cuenta</span>
              <div style={{ margin: '8px' }}>
                {data.considerations.map((text, idx) => (
                  <Tooltip key={idx} title={text}>
                    <Chip style={{ margin: '3px 10px 3px 0px', maxWidth: '400px' }} 
                      variant="default"
                      size="small"
                      avatar={<Avatar>{idx + 1}</Avatar>}
                      label={text}
                    />
                  </Tooltip>
                ))}
              </div>
            </>}
          </Grid> */}
          <Grid xs={12}>
            <TagList title="A TENER EN CUENTA" values={data?.considerations || []} />
          </Grid>

      </Grid>
      
    </Section>
  );
}



function OptionView({ option }) {

  const { minPax, maxPax } = option;
  let paxText = '';

  if (minPax === 0 && maxPax === 0) {
    paxText = 'Sin límite de personas';
  }
  else if (minPax === maxPax) {
    paxText = `Para ${minPax} personas`;
  }
  else if (minPax === 0 && maxPax > 0) {
    paxText = `Hasta ${maxPax} personas`;
  }
  else if (minPax > 0 && maxPax > 0) {
    paxText = `De ${minPax} a ${maxPax} personas`;
  }
  else if (minPax > 0 && maxPax === 0) {
    paxText = `A partir de ${minPax} personas`;
  }
  else {
    paxText = undefined;
  }

  return (
    <Stack spacing={0}>
      <Typography variant="caption" color="GrayText" sx={{ color: '#5B5B5B', ml: 1, fontWeight: 'bold' }}>{option.name}</Typography>
      <Stack direction="row" spacing={1}>
        <Chip style={{ margin: '3px 10px 3px 0px' }}
          variant="default"
          size="small"
          // icon={<Euro />}
          avatar={<Avatar><Euro fontSize='14px' /></Avatar>}
          label={`${option.price}€ / ${option.priceType === 'GROUP' ? 'grupo' : 'persona'}`}
        />
        {paxText !== undefined && <Chip style={{ margin: '3px 10px 3px 0px' }}
          variant="default"
          size="small"
          // icon={<People />}
          avatar={<Avatar><People fontSize='14px' /></Avatar>}
          label={paxText}
        />}
        {/* <Chip size="small" variant="outlined" label="PAX: ≥ 15" /> */}
      </Stack>
    </Stack>
  );
}



/*
export interface IProductData {
  productId: number

  validatedAt: Date

  name: string
  title: string
  description: string // HTML formatted content

  duration: string

  included: string[]
  considerations: string[]

  image: string
  
  options: IProductOption[]
}
export interface IProductOption {
  name: string
  price: number
  PriceType: PriceType // type PriceType = 'PERSON' | 'GROUP'
  minPax: number
  maxPax: number
}
export interface IProduct {
  providerId: number
  status: string
  createdAt: Date
  updatedAt: Date

  attachments: string[]

  data?: IProductData
  proposal?: IProposalData
}
*/
